.overlay {
  width: 100%;
  height: 100%;
  background-color: #413c3c52;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
}

.popup {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  align-items: center;
  position: absolute;

  &-container {
    display: flex;
    flex-direction: column;
    padding: 30px 40px;
    gap: 37.5px;
    width: 518px;
    height: 265px;
    background: #ffffff;
    border-radius: 12px;
    z-index: 1000;

    @include mq(1143px, max) {
      width: 450px;
      height: 260px;
    }

    @include mq(768px, max) {
      width: 400px;
      height: 211px;
      gap: 20px;
    }
    @include mq(426px, max) {
      width: 300px;
      height: 270px;
      gap: 15px;
    }
    @include mq(320px, max) {
      width: 272px;
      height: 205px;
      padding: 20px;
      gap: 15px;
    }
  }

  &-header {
    width: 100%;
    display: flex;
    justify-content: space-between;

    & p {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      color: #177c9a;

      @include mq(1143px, max) {
        font-size: 18px;
      }
      @include mq(768px, max) {
        font-size: 16px;
      }
      @include mq(426px, max) {
        font-size: 14px;
      }
    }
    & img {
      cursor: pointer;
    }
  }

  &-main {
    & p {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 25px;
      color: #959595;

      @include mq(1143px, max) {
        font-size: 13px;
      }
      @include mq(768px, max) {
        font-size: 12px;
      }
      @include mq(426px, max) {
        font-size: 10px;
      }
    }
  }

  &-footer {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    @include mq(426px, max) {
      gap: 20px;
    }
    &-oui {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 10px;
      gap: 10px;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;

      width: 213px;
      height: 51px;

      color: #177c9a;
      border: 1.5px solid #177c9a;
      border-radius: 8px;

      @include mq(1143px, max) {
        font-size: 12px;
        width: 143px;
        height: 32px;
      }
    }

    &-non {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 10px;
      gap: 10px;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;

      width: 213px;
      height: 51px;

      color: #ffffff;
      background: #177c9a;
      border-radius: 8px;

      @include mq(1143px, max) {
        font-size: 12px;
        width: 143px;
        height: 32px;
      }
    }

    &-supprimer {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 10px;
      gap: 10px;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;

      width: 213px;
      height: 51px;

      color: #ffffff;
      background: #f04438;
      border-radius: 8px;

      @include mq(1143px, max) {
        font-size: 12px;
        width: 143px;
        height: 32px;
      }
    }
  }
}
