.btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 2rem;
  justify-content: center;

  &:hover {
    transition: all 0.1s ease;
    box-shadow: 0px 4px 6px 0px rgba(50, 50, 93, 0.11), 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
    transform: scale(1.01);
  }

  &_content {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: start;

    p {
      @include mq(400px, max) {
        font-size: 1.3rem !important;
      }

      @include mq(360px, max) {
        font-size: 1rem !important;
      }
    }

  }
}

.btn-noHover {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 2rem;
  justify-content: center;
}