.kebab {
  cursor: pointer;
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  padding: 0 3px;
  figure {
    width: 6px;
    height: 6px;
    border-radius: 5px;
    background: $cyan;
    margin: 3px 0;
  }
}

.middle {
  cursor: pointer;
  transition: all 0.25s $cubic-in;
  transform: scale(1);
  position: relative;
  box-shadow: 0 0.1px 0.1px 0 rgba(0, 0, 0, 0.16), 0 0.1px 0.3px 0 rgba(0, 0, 0, 0.12);
  -webkit-filter: blur(0.1px);
  filter: blur(0.1px);
}
.error-message {
  color: rgb(208, 42, 12);
  font-size: 12px;
}
// Other styling

.dropdown-filter-students {
  position: absolute;
  z-index: 900;
  right: -70px;
  top: 20px;
  transition: all 0.25s ease-out;
  transform: scale(0);
  background: $color-0;
  border-radius: 5px;
  width: 220px;
  padding: 5px;
  max-height: 199px;
  overflow: scroll;
  & > li {
    display: block;
    width: 100%;
    &:hover {
      background: #f0f6f8;
      border-radius: 5px;
    }
    & > button {
      all: unset;
      text-decoration: none;
      width: 100%;
      padding: 5px;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #727272;
      cursor: pointer;
      display: flex;
      &:hover {
        text-decoration: none;
      }
    }
  }
  &:hover {
    ul {
      background: #f0f6f8;
      border-radius: 5px;
      transform: scale(1);
    }
  }
}

.dropdown-filter-students.active {
  transform: scale(1);
  transition: all 0.25s cubic-bezier(0.5, 1.8, 0.9, 0.8);
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.5);
}
.import-csv {
  display: block !important;
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  cursor: pointer;
  top: 0;
}
