.se-toolbar.sun-editor-common {
  background-color: white !important;
}
.sun-editor .se-list-layer {
  background-color: white !important;
}

.sun-editor {
  border: none !important;
}

.sun-editor .se-resizing-bar {
  display: none !important;
}
.sun-editor .se-toolbar {
  border-radius: 8px;
  z-index: 9;
  outline: none !important;
  border: none !important;
}
.sun-editor .se-tooltip {
  height: 25px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.sun-editor .se-list-layer.se-list-font-family,
.sun-editor .se-list-layer.se-list-format,
.sun-editor .se-list-layer.se-list-font-size {
  height: 149px;
}
.se-menu-list {
  overflow: scroll;
}
blockquote p,
.question-input > * {
  word-break: break-all !important;
  width: 100% !important;
  border: none;
}
.ck-powered-by {
  display: none !important;
}
.ck-content {
  box-shadow: none !important;
  border: none !important;
}
.se-btn {
  svg g path {
    fill: rgb(99, 99, 99);
  }
}
.sun-editor .se-btn-module-border {
  border: 0 !important;
}
.navigation-links {
  display: flex;
  gap: 1rem;
  a img {
    margin-left: 1rem;
  }
}
.se-dialog {
  &-content {
    margin: 10% auto !important;
  }
  &-header {
    border: 0 !important;
    .se-modal-title {
      color: #1b8392 !important;
    }
  }
  &-tabs {
    display: none;
  }
  &-body div {
    border: 0 !important;
  }
  &-form {
    label {
      color: rgb(116, 116, 116) !important;
      font-family: 'Poppins' !important;
      font-size: 14px !important;
      font-weight: 500 !important;
      text-transform: capitalize !important;
    }
    .se-file-remove {
      display: none;
    }
    &:first-child {
      border: 0;
    }
    &:nth-child(2) {
      display: none;
    }
    &:nth-child(3) {
      display: none;
    }
    &:nth-child(4) {
      display: none;
    }
    .se-dialog-form-files {
      display: flex !important;
      justify-content: center !important;
      width: 20rem !important;
      height: 20rem !important;

      border: 0 !important;
      outline: 0 !important;
      margin-left: 32% !important;
      input {
        border: 0 !important;
        outline: 0 !important;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: fit-content !important;
        &::-webkit-file-upload-button {
          visibility: hidden;
        }
        &:active {
          outline: 0 !important;
          border: 0 !important;
        }
        &::before {
          font-family: 'Font Awesome 5 Brands';
          content: 'Select files \f16e';
          display: inline-block;
          border-radius: 3px;
          padding: 35px 50px;
          border-radius: 12px;
          outline: none;
          white-space: nowrap;
          transition: all 0.5s ease;
          border: 1px dashed #1b8392;
          color: #1b8392;
          background: white;
          font-size: 12px;
          font-weight: 400;
          transition: all 0.5s ease;
          -webkit-user-select: none;
          cursor: pointer;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 3;
        }

        &:active::before {
          transition: all 0.5s ease;
          background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
        }
        width: fit-content !important;
      }
    }
  }
  &-footer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    div {
      display: none;
    }
    .se-btn-primary {
      width: 60% !important;
      background-color: #1b8392 !important;
      border: 1px solid #1b8392 !important;
      color: #1b8392 !important;
      position: relative !important;
      &:before {
        font-family: 'Font Awesome 5 Brands';
        content: 'Save    \f3fe';
        position: absolute;
        font-weight: 400;
        font-size: 14px;
        top: 50%;
        left: 50%;
        color: white;
        transform: translate(-50%, -50%);
      }
    }
  }
}

.sun-editor .se-list-layer .se-selector-color .se-color-pallet {
  padding: 0.5rem !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  flex-wrap: wrap !important;
  gap: 4px !important;
  li button {
    border-radius: 6px;
  }
}

.sun-editor .se-list-layer.se-list-font-size .se-list-basic li button {
  font-size: 12px !important;
}
