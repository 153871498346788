@import './bulletinTable';
.score {
  width: fit-content;
  padding: 10px 14px;
  text-align: center;
  border-radius: 20px;
  margin: 0 auto;
  &.green {
    background-color: #d1fadf;
    color: #12b76a;
  }
  &.yellow {
    background-color: #fff4d3;
    color: #fbb800;
  }
  &.red {
    background-color: #fee4e2;
    color: #f04438;
  }
}
