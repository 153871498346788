@use 'src/assets/styles/base';
@use 'src/assets/styles/abstracts'as *;
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.exams-container {
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

}

.exams-header {
  display: flex;
  padding: 0 14px 19px 5px;
  justify-content: space-between;
  align-items: flex-end;
  height: 8.8125rem;
  border-bottom: 1px solid #e8e8e8;
}

.ant-input-clear-icon {
  display: flex !important;
  align-items: center !important;
}

.exams-header-left-side {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  // height: 40px !important;
}

.exams-header-left-side-path {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #727272;
}

.exams-header-left-side-title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #1b8392;
  margin-top: 20px;
}

.exams-header-right-side {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 11px;
  width: 100%;
}

@media only screen and (max-width: 445px) {
  .exams-header-right-side {
    display: flex;
    flex-direction: column;
  }
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.blue-btn {
  background-color: #1b8392;
  padding: 8px 6px;
  gap: 10px;
  border-radius: 8px;
  border: 0;
  color: $color-0;
  width: 89px;
}

.add-exam-btn {
  background-color: #1b8392;
  padding: 8px 6px;
  gap: 10px;
  border-radius: 8px;
  border: 0;
  color: $color-0;
  width: 100%;
  min-width: 150px !important;
  height: 40px;
}

.search-exam-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 15px;
  background-color: white;
  border: 1px solid #1b8392;
  border-radius: 8px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #1b8392;
  width: 100%;
  gap: 6px;
}

.filter-exam-btn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 15px;
  background-color: white;
  border: 1px solid #1b8392;
  border-radius: 8px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #1b8392;
  width: 100%;
  gap: 5px;
}


.exams-list {

  overflow: scroll;

  article {
    padding: 0 67px;
    gap: 2rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 20px;
  }

}

.exam-card {

  display: flex;
  cursor: pointer;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 24px 21.5px 23px 20.11px;
  row-gap: 10px;

  background: #f3f6f7;
  border-radius: 25px;

  @include mq(420px, max) {
    min-width: 200px;
    width: 100%;
  }


  &>.exam-card-top {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0px;
    width: 100%;

    &>.exam-card-top-details {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      height: 100%;

      &>.exam-title {
        word-break: break-word;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 31px;
        color: #514e4e;
      }
    }
  }

  &>.exam-card-center-and-bottom {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 2.5rem;
    width: 100%;
    height: 100%;

    &>.exam-card-center {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      padding: 0px;
      width: 100%;
      height: 100%;

      &>.exam-card-center-details {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        height: 100%;

        &>.exam-title {
          word-break: break-word;
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 500;
          font-size: 19.5288px;
          gap: 6px;
          line-height: 22px;
          color: #1b8392;
        }

        &>.exam-description {
          font-family: 'Poppins';
          font-style: italic;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: #8c8c8c;
        }
      }

      &>.exam-card-center-correction-average {
        display: flex;
        flex-direction: column;
        padding: 10px 7px;
        justify-content: center;
        align-items: center;
        background: #1b8392;
        border-radius: 12px;
        align-self: end;

        p {
          color: #fff;
          font-family: 'Poppins';
          text-align: center;

          &:first-child {
            font-size: 14px;
            font-weight: 600;
            text-transform: uppercase;
          }

          &:last-child {
            font-size: 11px;
            font-weight: 500;
            text-transform: capitalize;
          }
        }
      }
    }

    &>.exam-card-bottom {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 0px;
      gap: 25.11px;
      width: 100%;
      height: 7.53px;
      background: white;
      border-radius: 15.0651px;

      &>.exam-card-bottom-loading {
        height: 7.53px;
        background: #1b8392;
        border-radius: 15.0651px;
      }
    }
  }
}

///////ADD exam INPUTS/////
.exams-adding {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 38px 24px 31px 20px;
  border-bottom: 1px solid #e4e7ec;
}

.exam-input {
  display: flex;
  flex-direction: column;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #898989;
  row-gap: 10px;

  input {
    height: 60px;
    border: 1px solid #e8e8e8;
    border-radius: 8px;
    padding: 18px;
  }

  input::placeholder {
    font-family: 'Poppins';
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: #a8a8a8;
  }

  input:hover {
    background-color: #f2f2f2;
    color: #000000;
  }
}

.save_exam {
  height: 44px;
  width: 9%;
  transform: translate(0%, 70%);
  display: flex;
  justify-content: center;
}

input[type='file'] {
  display: none;
}

.exam-img_input {
  display: flex;
}

@media only screen and (max-width: 719px) {
  .exams-header {
    display: flex;
    flex-direction: row;
    align-items: center;

    justify-content: space-between;
    row-gap: 10px;
    height: 8.8125rem;
    border-bottom: 1px solid #e8e8e8;
  }

  .exams-adding {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 38px 24px 31px 20px;
    border-bottom: 1px solid #e4e7ec;
    row-gap: 10px;
  }

  .save_exam {
    height: 44px;
    width: 100%;
    transform: translate(0%, 20%);
    display: flex;
    justify-content: center;
  }
}

@media only screen and (max-width: 635px) {
  .exams-header {
    display: flex;
    flex-direction: column;
    height: 14rem;
  }

  .exams-header-right-side {
    flex-direction: column;
  }
}

@media only screen and (max-width: 445px) {
  .exams-header {
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (max-width: 340px) {
  .exams-header {
    display: flex;
    flex-direction: column;
  }
}

/////////////////
///
///
///
$cubic-out: cubic-bezier(0.32, 2.04, 0.85, 0.54);
$cubic-in: cubic-bezier(0.72, 1.2, 0.71, 0.72);
$nunito: 'Nunito',
sans-serif;
$roboto: Roboto,
sans-serif;

$cyan: #00bcd4;
$yellow: #ffeb3b;
$grey: #9e9e9e;

$shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16),
0 2px 8px 0 rgba(0, 0, 0, 0.12);

// Kebab styling

.middle {
  cursor: pointer;
  transition: all 0.25s $cubic-in;
  transform: scale(1);
  position: relative;
  box-shadow: 0 0.1px 0.1px 0 rgba(0, 0, 0, 0.16), 0 0.1px 0.3px 0 rgba(0, 0, 0, 0.12);
  -webkit-filter: blur(0.1px);
  filter: blur(0.1px);
}

// Other styling

.dropdown {
  position: absolute;
  z-index: 900;
  right: 0;

  transition: all 0.25s ease-out;
  transform: scale(0);
  background: $color-0;
  border-radius: 5px;
  width: 131px;

  padding: 5px;

  // box-shadow: $shadow;
  &>li {
    display: block;
    width: 100%;

    &:hover {
      background: #f0f6f8;
      border-radius: 5px;
    }

    &>button {
      all: unset;
      text-decoration: none;
      width: 100%;
      padding: 5px;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #727272;
      cursor: pointer;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &:hover {
    ul {
      background: #f0f6f8;
      border-radius: 5px;
      transform: scale(1);
    }
  }
}

.ant-modal-content {
  padding: 4rem !important;
}

.ant-modal {
  padding: 10px 0px !important;

}

.dropdown.active {
  transform: scale(1);
  transition: all 0.25s cubic-bezier(0.5, 1.8, 0.9, 0.8);
}

.delete-exam-popup-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 25px;
  margin-top: 50px;

  &>.delete-exam-popup-message {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    display: flex;
    align-items: center;
    color: #959595;
  }

  &>.delete-exam-popup-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 15px;
    padding: 0 1rem;

    .btn {
      height: 45px !important;
    }

    &>.white-btn {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 10px;
      gap: 10px;
      width: 100%;
      border: 1px solid #177c9a;
      border-radius: 8px;
      background-color: white;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;

      color: #177c9a;
    }

    &>.red-btn {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 10px;
      gap: 10px;
      width: 100%;
      background: #f04438;
      border-radius: 8px;
      border: 0 !important;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: white;
    }
  }
}

////////ARCHIVE POPUP
.archive-exam-popup-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 25px;
  margin-top: 50px;

  &>.archive-exam-popup-message {
    color: #959595;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
  }

  &>.archive-exam-popup-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 15px;
    padding: 0 1rem;

    .btn {
      height: 45px !important;
    }

    &>.white-btn {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 10px;
      gap: 10px;

      width: 100%;
      height: 51px;
      border: 1px solid #177c9a;
      border-radius: 8px;
      background-color: white;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;

      color: #177c9a;
    }

    &>.blue-btn {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 10px;
      gap: 10px;
      width: 100%;
      height: 51px;
      background: #177c9a;
      border-radius: 8px;
      border: 0 !important;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: white;
    }
  }
}

/////ModalDesign
.ant-modal-title {
  font-family: 'Poppins' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 30px !important;
  color: #1b8392 !important;
}

.formik-modal-group {
  display: flex;
  flex-direction: column;
  row-gap: 7px;
  align-items: flex-start;
  width: 100%;

  .formik-modal-label {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-transform: capitalize;
    color: #999999;

    #red-star {
      color: red;
    }
  }
}

.css-dev-only-do-not-override-mxhywb .ant-input-number:hover {
  border-inline-end-width: 0 !important;
}

.formik-modal-add-exam-style {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 44px;
  gap: 25px;

  &>.add-exam-style {
    display: flex;
    justify-content: space-around;
    gap: 10px;
    align-items: center;
    width: 100%;
    border-radius: 10px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    height: 100%;
    color: #999999;
    background: $color-0;
    border: 1px solid #e4e4e4;
    border-radius: 10px;
    cursor: pointer;
  }

  &>.active {
    background: #f0f6f8;
    border: 1px solid #1b8392;
    border-radius: 10px;
    color: #1b8392 !important;
  }
}

.hidden-text {
  display: none !important;
}

.ant-popover-inner {
  background-color: #f3f6f7 !important;

  padding: 15px !important;
}

.ant-popover-inner-content {
  background-color: #f3f6f7 !important;

  height: fit-content !important;
}

.ant-popover-arrow {
  background-color: #f3f6f7 !important;
}

.popover-exam-content {
  font-family: 'Poppins' !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 15px !important;
  color: #1b8392 !important;
  max-height: 220px !important;
  background-color: #f3f6f7 !important;
  overflow: scroll;
  display: flex !important;
  flex-direction: column !important;
  row-gap: 15px !important;
  padding: 5px !important;
}

.ant-modal .ant-modal-header {
  background-color: transparent !important;
}

.ant-modal-body {
  &>.ant-form {
    &>div {
      &>.ant-form-item {
        margin-bottom: 0 !important;
      }
    }
  }
}

.class-title-container {
  .class-title {
    border: 1px solid #1b8392;
    border-radius: 50px;
    color: #1b8392;
    font-size: 15px;
    cursor: pointer;
    padding: 0.4rem 4.6rem !important;
    font-size: 12px;
    font-weight: 500;
    width: 65px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  display: flex;
  flex-wrap: wrap;
  gap: 0px;
}

.exam-card-center-details {
  gap: 2rem;
}

.classes-input {
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 10px;

  .ant-form-item {
    width: 98%;
  }
}

input::placeholder {
  color: rgba(0, 0, 0, 0.25) !important;
}

.ant-input-affix-wrapper-focused {
  border: none;
  box-shadow: none !important;
}

.ant-form-item .ant-form-item-explain-error {
  font-size: 1.5rem !important;
}

.ant-form-item .ant-form-item-control-input {
  margin-bottom: 0.5rem !important;
}

.ant-select-selector {
  border-radius: 10px !important;

  overflow: auto;

  .ant-select-selection-item {
    max-width: 20rem;
  }
}

.createExam__input {
  .ant-select-selector {

    border-radius: 10px !important;

  }

  .ant-input {

    border-radius: 10px !important;
  }
}

.ant-input-number {
  height: 50px !important;
  padding: 0 1rem;
  border-radius: 10px !important;
}

.ant-modal-close {
  svg {
    width: 26px;
    height: 26px;

    path {
      fill: #d9d9d9;
    }
  }
}

.ant-form-item {
  margin-bottom: 12px !important;
}

.ant-form-item .ant-form-item-label>label {
  display: none !important;
}