.css-b62m3t-container {
  width: 100% !important;
}
.css-13cymwt-control {
  width: 100% !important;
  border: 0 !important;
}
.css-1jqq78o-placeholder {
  margin: 0 !important;
  font-family: 'Poppins' !important;
  font-style: italic !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  display: flex;
  align-items: center !important;
  color: #727272;
}
.css-1xc3v61-indicatorContainer {
  padding: 0 !important;
  color: #727272 !important;
  cursor: pointer !important;
}
.css-1u9des2-indicatorSeparator {
  margin: 0 !important;
  padding: 0 !important;
  display: none;
}
.css-1fdsijx-ValueContainer:active {
  border: 0 !important;
}
.css-1fdsijx-ValueContainer:hover {
  border: 0 !important;
}
.css-13cymwt-control:active {
  border: 0 !important;
}
.css-13cymwt-control:hover {
  border: 0 !important;
}
.css-t3ipsp-control:hover {
  border: 0 !important;
}
.css-t3ipsp-control:active {
  border: 0 !important;
}
.css-t3ipsp-control {
  border: 0 !important;
  box-shadow: 0 0 0 0px !important;
}
.form-group-single-select {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  row-gap: 10px;
  width: 100%;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #727272;
  .form-group-input-single-select:focus-within {
    border: 1.5px solid #177c9a;
    border-radius: 10px;
    color: #727272;
    input {
      color: #727272;
    }
  }
  #error {
    border: 1.5px solid #f04438;
  }
  .form-group-input-single-select {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    gap: 1rem;
    border: 1.5px solid #d9d9d9;
    border-radius: 10px;

    input {
      border: 0;
      width: 100%;
      font-family: 'Poppins';
      font-style: italic;
      font-weight: 400;
      font-size: 16px;
      line-height: 34px;
      color: #959595;
      outline: none;
    }
    input:focus {
      border: 0;
    }
  }
  .input-field-icon {
    fill: #727272;

    width: 28px;
    height: 21.33px;
    z-index: 10;
  }
  .eye-icon {
    cursor: pointer;
    width: 30px;
    z-index: 10;
  }
}
.form-group-title-single-select {
  display: flex;
  .red-star {
    color: red;
  }
}
.css-1dimb5e-single-value {
  font-family: 'Poppins' !important;
  font-style: italic !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  display: flex !important;
  align-items: center !important;
  color: #727272 !important;
}
.css-15lsz6c-indicator-container {
  padding: 0 !important;
}
