@use 'src/assets/styles/base';
@use 'src/assets/styles/abstracts'as *;

.dashboard {
  flex-direction: column;
  display: flex;
  padding: 3rem 5.5rem 5.5rem 5.5rem !important;
  gap: 3.85rem;

  &__title {
    font-weight: 600;
    font-size: 2.4rem;
  }

  &_content {
    display: flex;
    gap: 3rem;
    justify-content: space-between;
    flex-wrap: wrap;

    &-container {
      flex-basis: 100%;
      max-width: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 2rem;
      border: 0.1rem solid $color-8;
      border-radius: 2rem;

      &:has(.exams_stats) {
        position: relative;
        height: 144px;

        @include mq(936px, max) {
          min-height: 417px;
        }

        @include mq(500px, max) {
          min-height: 80rem;
        }
      }

      position: relative;
      min-height: 230px;

      &--class-empty {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-around;

        &-btn {
          display: flex;
          flex-direction: column;
          gap: 25px;
          align-items: center;

          p {
            font-weight: 400;
            font-size: 19px;
            line-height: 8px;

            color: #1b8392;
          }

          button {
            width: 80%;
          }
        }

        @include mq(557px, max) {
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
      }
    }

    &-container:has(.max_classes_container) {
      gap: 5rem;
      display: grid;
      grid-template-columns: repeat(auto-fill, 28%);

      @include mq(960px, max) {
        grid-template-columns: repeat(auto-fill, 250px);
      }
    }

    &_correction {
      flex-basis: 100%;
      max-width: 100%;

      &-selectors {
        display: flex;

        @include mq(415px, max) {
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }

        .ant-select-selector {
          padding-top: 0.4rem !important;
          height: 35px !important;
        }

        gap: 1rem;
      }
    }

    &-right,
    &-left {
      display: flex;
      flex-direction: column;
      gap: 5rem;
      flex-grow: 1;

      @include mq(470px, max) {
        flex-basis: 100%;
        max-width: 100%;
      }

      @include mq(329px, max) {
        gap: 6.6rem;
      }
    }

    &-left {
      .dashboard_content_section {
        &:first-child {
          animation: EnterDashboardtop 1s cubic-bezier(0.075, 0.82, 0.165, 1);
        }

        &:nth-child(2) {
          animation: EnterDashboardLeftside 1s cubic-bezier(0.075, 0.82, 0.165, 1);
        }

        &:nth-child(3) {
          animation: EnterDashboardLeftside 1s cubic-bezier(0.075, 0.82, 0.165, 1);
        }
      }
    }

    &-right {
      animation: EnterDashboardRightside 1s cubic-bezier(0.075, 0.82, 0.165, 1);
    }

    &_section {
      height: 100%;
      max-width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;

      @include mq(1707px, max) {
        padding-top: 31px;
      }

      &-content {
        min-height: 100%;
        display: flex;
        gap: 15px;
        flex-wrap: wrap;
        height: fit-content;

        table thead tr th :first-child {
          padding-left: 0 !important;
        }

        .ant-table-cell svg circle {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}

.table-wrapper {
  margin: 0 30px;
  box-shadow: 0px 0px 15px 0px #d2d1d199;
  max-height: 0vh !important;
  width: -webkit-fill-available !important;
  min-height: unset !important;
}

.ant-table-wrapper .ant-table-tbody>tr>td {
  border-bottom: none !important;

}

.ant-upload-wrapper .ant-upload-list.ant-upload-list-picture-circle .ant-upload-list-item-error {
  border-color: lightgray;
}

.ant-empty-normal {
  display: none !important;
}

.percent_status {
  font-weight: 600;
  font-size: 1.6rem;
  white-space: nowrap;
  line-height: 2.4rem;
  display: flex;
  align-items: center;
  text-align: center;
  gap: 0.5rem;

  p {
    text-align: center;
    font-family: 'Poppins';

    &:first-child {
      font-weight: 600;
      text-transform: uppercase;
      font-size: 20px;
    }

    &:last-child {
      font-size: 16px;
      font-weight: 400;
    }
  }
}

.table_container {
  display: flex;
  min-height: 85%;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  gap: 40px;
  flex-grow: 1;
  overflow: auto;
  width: 65rem;
  height: fit-content;



  .ant-table-wrapper {
    padding: 0 1rem !important;
    border-radius: 5px !important;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .ant-table-body {
    max-height: 350px !important;



    tr td {
      border: none !important;
      border-bottom: 0.5px solid rgb(233, 233, 233) !important;

      &:first-child {
        padding: 0.2 !important;
        padding-right: 0 !important;

      }
    }
  }

  border: 1px solid $color-8;
  border-radius: 20px;

  button {
    font-size: 16px;
    font-weight: 600;
  }

  &>img {
    padding-top: 30px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    width: 10px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #000000;
    border: 2px solid #555555;
  }

  &-status {
    font-weight: 400;
    font-size: 1.9rem;
    line-height: 2.8rem;
  }

  .ant-table-wrapper {
    max-height: 40rem;
    padding: 0 !important;
    min-width: 100%;

    .ant-table-container {
      &::after {
        display: none !important;
      }

      &::before {
        display: none !important;
      }

      & table>thead>tr:first-child>* {
        font-size: 1.4rem;
        line-height: 2.5rem;
        font-weight: 500;
        color: $color-2;

        &:first-child {
          border-top-left-radius: 10px !important;
          border-bottom-left-radius: 10px !important;
        }

        &:last-child {
          border-top-right-radius: 10px !important;
          border-bottom-right-radius: 10px !important;
        }
      }
    }
  }
}

.ant-image {
  border-radius: 50%;

  .ant-image-img {
    border-radius: 50%;
  }
}

.ant-table-thead {
  & tr {
    .ant-table-cell {
      border: none;
      background: #f0f6f8;

      &::before {
        display: none;
      }
    }
  }
}

.status_progress {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  width: 13rem;
  border-radius: 1.6rem;
  padding: 6px 8px;

  &_round {
    border-radius: 50%;
    width: 6px;
    height: 6px;
  }

  &_text {
    font-weight: 400;
    font-size: 12px;
    line-height: 1.8rem;
  }
}

.btn_see_more {
  font-size: 1.8rem;
  line-height: 2.7rem;
  display: flex;
  background-color: transparent;
  cursor: pointer;
  align-items: center;

  text-decoration-line: underline;
  color: $color-2;
}

.max_classes_container {
  justify-content: center;
  gap: 12rem;
  display: flex;
  width: 100%;
  align-items: start;
}

.class_card {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  padding: 15px 20px;
  gap: 22px;
  width: 260.5px;
  height: 271px;
  background: $color-4;
  border-radius: 20px;

  @include mq(480px, max) {
    width: unset;
    min-width: unset;
  }

  &-header {
    display: flex;
    justify-content: space-around;
    width: 100%;
    align-items: center;

    &-dots {
      background-color: transparent;
      color: transparent;
      cursor: pointer;
    }
  }

  &>button {
    text-transform: none !important;
    font-weight: 600;
  }
}

.exams_stats {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 1rem;

  .pie-div {
    margin-left: -15rem;

    @include mq(1120px, max) {
      margin-left: -16rem;
    }

    @include mq(936px, max) {
      margin-left: 0;
    }

    max-height: 230px;

    & div[data-chart-source-type='G2Plot'] {
      margin-top: -10rem;
    }
  }

  .g2-html-annotation {
    color: #4c4c4d !important;
    text-align: center !important;
    font-family: 'Poppins' !important;
    font-size: 32px !important;
    font-weight: 500 !important;
    position: relative;
    top: 180px !important;
    height: 10rem;

    &::before {
      content: 'Total Etudiants ';
      color: #727272;
      font-size: 10.814px;
      font-style: normal;
      font-weight: 400;
      line-height: 18.565px;
      position: absolute;
      bottom: 0rem;
    }
  }

  &_pallette {
    display: flex;
    align-items: start;
    justify-content: start;
    flex-wrap: wrap;
    gap: 3rem;

    @include mq(600px, max) {
      align-items: center;
      justify-content: center;
    }

    @include mq(390px, max) {
      flex-direction: column;
    }

    &-element {
      display: flex;
      gap: 1.3rem;
      justify-content: center;
      align-items: center;

      @include mq(470px, max) {
        flex-basis: 48%;
        max-width: 49%;
      }

      @include mq(387px, max) {
        flex-basis: 33%;
        max-width: 49%;
      }
    }

    &_el {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      &>* {
        white-space: nowrap;
      }
    }

    &_type {
      font-weight: 400;
      font-size: 15px;
      line-height: 28px;

      display: flex;
      align-items: center;
    }

    &_value {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 36px;
      display: flex;
      align-items: center;
    }

    &-color {
      width: 5.08px;
      height: 56px;
      border-radius: 126.893px;
    }
  }
}

.footer {
  display: flex;
  gap: 14px;
  justify-content: start;
  align-items: start;
  flex-direction: column;
}

.ant-radio-wrapper span.ant-radio+* {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: $color-5;
}

.antd-option-column {
  flex-direction: column !important;
  align-self: center;
  justify-self: center;
}

.footer-establishment-title {
  margin: 0 0 0 5px;
}

.user_details {
  display: flex;
  flex-direction: column;
  align-items: start;

  @include mq(400px, max) {
    display: none;
  }
}

.icon-holder {
  display: flex;

  @include mq(400px, max) {
    display: none;
  }
}

main {
  overflow-x: hidden !important;
}

.title_container {
  @include mq(430px, max) {
    &>* {
      justify-content: center;
      gap: 1rem;
    }
  }

  .component-title {
    flex-wrap: wrap;
  }
}

.dashboard {
  .component-title {
    justify-content: space-between !important;
  }
}

.ant-modal-mask {
  background-color: #0000000f !important;
}

.welcome-name {
  font-weight: 700;
  font-size: 40px;
  white-space: nowrap;
  color: #1b8392;
}

.welcome {
  font-weight: 400;
  white-space: nowrap;
  font-size: 40px;
  color: #1b8392;
}

.ant-image .ant-image-img {
  max-height: 48px !important;
  width: 44px;
  height: 44px;
}

.ant-input-borderless {
  max-width: 600px !important;
}

.exercice-title {
  height: fit-content !important;
  min-height: 60px;
}

input:focus {
  outline: none;
}

.ant-modal .ant-modal-content {
  box-shadow: none !important;
}

.class-date {
  color: $color-10;
  font-size: 14px;
}

.ant-table-column-sort {
  background: #fafafa !important;
}

.--table__2 {


  .ant-table-header {
    border-radius: 10px !important;

    tr {
      padding: 0 1rem !important;
    }

    th {
      padding: 1rem !important;

      &:first-child {
        svg {
          transform: scale(1.4);
          margin-left: 2rem;
          margin-top: 0.5rem;
        }
      }

      &:nth-child(3) {
        width: 20rem !important;
        padding-left: 6rem !important;
      }

      &:nth-child(4) {
        width: 10rem !important;
      }
    }
  }

  .ant-table-body {
    overflow: visible !important;
    max-height: 350px !important;


    tr td {
      &:first-child {
        width: 8rem !important;
      }

      &:nth-child(2) {
        width: 18rem;
      }

      &:nth-child(3) {
        color: #4c4c4d !important;
        font-size: 13px !important;
        font-weight: 400 !important;
        width: 18rem;
        padding-left: 4rem;
      }

      &:nth-child(4) {
        font-size: 16px !important;
        font-weight: 500 !important;
        width: 13rem;
        padding-left: 0;
      }

      &:nth-child(5) {
        width: 8rem;
        background: transparent !important;
        padding-left: 4rem;
      }
    }
  }
}

@keyframes EnterDashboardLeftside {
  from {
    transform: translateY(100%);
    opacity: 0.5;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes EnterDashboardRightside {
  from {
    transform: translateX(100%);
    opacity: 0.5;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes EnterDashboardtop {
  from {
    transform: translateX(-100%);
    opacity: 0.5;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}


.welcome-body {
  width: 40% !important;
  gap: 10px;
  display: flex;
  flex-wrap: wrap;

  .welcome-name {
    font-weight: 700;
    font-size: 40px;
    white-space: nowrap;
    color: #1b8392;
  }

  .welcome {
    font-weight: 400;
    white-space: nowrap;
    font-size: 40px;
    color: #1b8392;
  }

  .ant-modal-mask {
    background-color: rgba(255, 255, 255, 0.6) !important;
  }

  &-container {
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
  }
}

.welcome {
  &-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.60);
    filter: blur(4px);
    z-index: 9999;

  }

  &-body {
    position: absolute;
    z-index: 10000;
    top: 50%;
    border-radius: 8px;
    right: 50%;
    transform: translate(50%, -50%);
    padding: 4rem 6rem;
    box-shadow: 15px 21px 21px -11px rgba(131, 136, 197, 0.5);
    background-color: #F0F6F8;
    animation: modalAnimation 1s ease;
  }
}

@keyframes modalAnimation {
  from {

    transform: translate(50%, -400%);
    opacity: 0.5;
  }

  to {

    opacity: 1;
  }
}

.ant-table-wrapper {
  padding: 0 0 7rem 0 !important;
  overflow: auto;
  border-radius: 5px !important;
}

.students-list {
  height: calc(100vh - 276px) !important;
}

.students-list .ant-table {
  height: fit-content !important;
}

.student-list-pag {
  .ant-table-wrapper {
    border-radius: 5px !important;
    overflow: auto;
    padding: 0 0 7rem 0 !important;
  }

}

.MuiPickersLayout-contentWrapper {

  button.MuiPickersDay-root.Mui-selected,
  .MuiClockPointer-root,
  .MuiClock-pin,
  .MuiClockPointer-root,
  .MuiTabs-indicator,
  .MuiClockPointer-thumb {
    border-color: $color-2 !important;
    background-color: $color-2 !important;
  }

  button.Mui-selected.MuiTab-textColorPrimary {
    color: $color-2 !important;
    border-color: $color-2 !important;

  }
}

.admin-table {
  .ant-select-selection-item {
    margin-top: 1rem;
  }
}