@use 'src/assets/styles/base';
@use 'src/assets/styles/abstracts' as *;
.auth-container-signup {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  background: #f0f6f8;
  @media (max-width: 650px) {
    padding: 1rem;
  }
  @media only screen and (max-width: 520px) {
    height: 100%;
  }
  .auth-form-signup {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 52.8%;

    background: $color-0;
    border-radius: 100px 0px 0px 100px;
    @media only screen and (max-width: 1241px) {
      width: 80%;
    }
    @media only screen and (max-width: 1000px) {
      width: 100%;
      border-radius: 0;
    }

    .form-container-signup {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      max-height: 871px;
      width: 83.333%;
      height: 100%;
      @media only screen and (max-width: 650px) {
        width: 95%;
        height: 100vh;
        overflow-y: auto;
        justify-content: start;
        padding-top: 12rem;
        gap: 2rem;
      }
      /* ----- */
      .form-title-signup {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 100%;
        .h1-signup {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 600;
          font-size: 45px;
          line-height: 68px;
          color: #177c9a;
          @media only screen and (max-width: 1125px) {
            font-weight: 600;
            font-size: 45px;
            line-height: 68px;
          }
          @media only screen and (max-width: 600px) {
            font-weight: 600;
            font-size: 40px;
            line-height: 56px;
          }
          @media only screen and (max-width: 525px) {
            font-weight: 600;
            font-size: 35px;
            line-height: 50px;
          }
        }
      }
      .form-role-signup {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px;
        gap: 16px;
        background-color: #99c6d3;
        border-radius: 50px;
        width: 100%;
        height: 70;

        .form-teacher-signup {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 10px 15px;
          gap: 16px;
          height: 54px;
          background-color: #99c6d3;
          border-radius: 50px;
          border: 0;
          color: $color-0;
          width: 100%;
          cursor: pointer;
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 30px;
        }
        .form-teacher-signup:disabled {
          background: #1b8392;
          cursor: not-allowed;
        }
        .form-student-signup {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 10px 15px;
          gap: 16px;
          height: 54px;
          background-color: #99c6d3;
          border-radius: 50px;
          border: 0;
          color: $color-0;
          width: 100%;
          cursor: pointer;
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 30px;
        }
        .form-student-signup:disabled {
          background: #1b8392;
          cursor: not-allowed;
        }
      }

      /* ----- */
      .formik-signup {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        .form-signup {
          padding-bottom: 3rem;
          display: flex;
          flex-flow: row wrap;

          width: 100%;
          justify-content: space-between;
          align-items: center;
   
          .under-form-signup {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 16.312%;

            .stay-connected-signup {
              font-family: 'Poppins';
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 25px;
              color: #727272;
            }
          }
        }
      }
    }
  }
}

/***********************/

/* --------------------- */

/* --------------------- */
/***********************/

.button-holder-signup {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  row-gap: 10px;
}
.login-signup p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #727272;
}
.auth-link-signup:link,
.auth-link-signup:visited {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;
  color: #1b8392;
}

.auth-link-signup:hover {
  color: #99c6d3;
}
.form_field {
  width: 100%;
  justify-content: space-between;
  gap: 4rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}
.form-group-input,
.form-group-input-single-select {
  min-height: 55px;
  max-height: 55px;
  height: 55px !important;
}
.form_item {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}
