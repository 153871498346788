body {
  background-color: #f0f6f8;
}

.layout {
  &-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100vh;
    background: #f0f6f8;
  }

  &-leftside {
    width: 40%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    @include mq(755px, max) {
      display: none;
    }

    &-img {
      position: absolute;
      top: 0;
      left: 0;

      @include mq(1440px, max) {
        width: 200px;
      }

      @include mq(1143px, max) {
        width: 190px;
      }
    }
  }

  &-title {
    &-top img {
      @include mq(1440px, max) {
        width: 260px !important;
      }

      @include mq(1143px, max) {
        width: 260px !important;
      }

      @include mq(926px, max) {
        width: 250px;
      }
    }
  }

  &-rightside {
    width: 65.2%;
    height: 100%;
    background-color: #ffffff;
    border-radius: 100px 0px 0px 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include mq(755px, max) {
      width: 100%;
      border-radius: 0;
    }
  }

  &-title {
    display: flex;
    flex-direction: column;
    gap: 50px;

    &-bottom {
      font-family: Poppins;
      font-size: 34px;
      font-weight: 600;
      line-height: 59px;
      letter-spacing: 0em;
      text-align: center;
      color: #4c4c4d;

      @include mq(1143px, max) {
        font-size: 30px;
      }

      @include mq(926px, max) {
        font-size: 25px;
      }
    }
  }
}
