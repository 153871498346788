.action-link {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  &.text-muted {
    color: #9e9e9e;
    transition: all 300ms ease;
    font-size: 12px;
    &:hover {
      color: #9e9e9e;
    }
  }
  & > .MuiSvgIcon-root {
    font-size: 20px !important;
  }
  &.full-width {
    flex-direction: row-reverse;
    width: 100%;
    & > .MuiSvgIcon-root {
      margin-right: 4px;
    }
  }
  .material-icon {
    width: 20px !important;
    height: 20px !important;
    //filter: invert(31%) sepia(72%) saturate(567%) hue-rotate(153deg) brightness(93%) contrast(88%);
  }
}
