@use 'src/assets/styles/base';
@use 'src/assets/styles/abstracts' as *;
.ant-float-btn-icon {
  margin-right: -9px;
  margin-left: -9px;
  width: 92px;
}
.correction__float-btn {
  position: absolute;
  bottom: 0px;
  right: 10px;
  background-color: transparent;
  height: fit-content;
  width: fit-content;
  transition: all 0.5 ease-in-out;
  &-open {
    top: -43px;
    left: -50px;
  }
}
button {
  cursor: pointer;
  background-color: transparent;
}
.ant-modal .ant-modal-footer {
  display: flex !important;
  align-items: center !important;

  justify-content: center !important;
  .ant-btn {
    width: 50% !important;
  }
  .ant-btn-primary {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;

    width: 233.5px;
    height: 51px;

    /* 2 */
    background: $color-2;
    border-radius: 8px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;

    /* 0 */
    color: $color-0;
  }
  .ant-btn-default {
    width: 233.5px;
    height: 51px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;

    /* 2 */
    color: $color-2;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;
  }
}
.correction-remark-modal-textArea {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  gap: 10px;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  min-height: 231px;
  padding: 10px;
}
.correction-remark-modal-textArea.arabe {
  direction: rtl;
}
.remark-tag {
  padding: 5px 10px;

  min-height: 35px;

  /* 4 */
  background: $color-4;
  border-radius: 8px;

  font-weight: 500;
  font-size: 13px;
  line-height: 25px;
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;

  color: $color-11;
  &-cancel {
    color: $color-11;
    background-color: transparent !important;
  }
}
.question-input div div {
  word-break: break-word !important;
}
