.Pagination {
  margin-top: 1rem;
  display: flex;
  gap: 1.5rem;
  align-items: center;
  justify-content: flex-end;
  margin-top: auto;
  align-self: end;
  margin-right: 1rem;

  @media screen and (max-width: 550px) {
    gap: 0.5rem;
  }

  &__Title {
    font-weight: 500;
    font-size: 14px;
    color: #374957;

    @media screen and (max-width: 550px) {
      font-size: 12px;
    }
  }

  &__SelectNumber {
    width: 40px;
    height: 30px;
    border: 1px solid grey;
    border-radius: 4px;
    padding: 0.6rem;
    font-size: 12px;
    font-weight: 500;
  }

  &__NumberPerPage {
    color: #98a2b3;
    font-weight: 400;
  }

  // &__Arrow {
  //
  // }
  .arrow {
    filter: invert(45%) sepia(10%) saturate(5%) hue-rotate(343deg) brightness(95%) contrast(100%);

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}