.navigation-links {
  a {
    color: #959595;
    &:hover {
      transition: all 0.1s ease;
      color: rgb(149, 149, 149, 0.8);

      transform: scale(1.01);
    }
  }
}
