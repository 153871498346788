.editable-line-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px 5px 15px;
  background: #ffffff;
  // box-shadow: 0px 0px 15px rgba(210, 209, 209, 0.6);
  border-radius: 15px;
  border-left: 4px solid #99c6d3;
  border-radius: 5px;
  & > .editable-line-data {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    row-gap: 8px;
    width: 100%;
    & > .editable-line-title {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 22px;
      display: flex;
      align-items: center;
      color: #727272;
    }
    & > .editable-line-content {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 22px;
      display: flex;
      align-items: center;
      color: #727272;

      width: 100% !important
      ;
      background: white;
      &:focus {
        color: #1b8392 !important;
      }
    }
  }
  & > .editable-line-edit-command {
    & > button {
      cursor: pointer;
      background: white !important;
    }
  }
}
.profile-upload-img {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  @media only screen and (max-width: 440px) {
    flex-direction: column;
    align-self: center;
  }

  .profile-upload-img-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    row-gap: 12px;
  }

  .profile-upload-img-details-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #727272;
    text-align: center;
  }
  .profile-upload-img-details-description {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: #f04438;
    text-align: center;
  }
}
.ant-upload-select {
  border: 2px dashed #1b8392 !important;
}
