.exam-correction {
  justify-content: space-between;
  display: flex;
  align-items: center;
  gap: 6rem;
  border-left: 4px solid $color-21;
  border-radius: 10px;
  @include mq($bulletin_exams_breakpoint, max) {
    flex-direction: column;
    border-left: none;
    border-top: 4px solid $color-21;
    width: fit-content;
    padding: 2rem 3rem;
    border-bottom: 4px solid #fff4d3;
  }
  &_desc {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    padding-left: 15px;
    padding-top: 3px;
    padding-bottom: 3px;
    &-date {
      font-size: 1.5rem;
      white-space: nowrap;
      color: $color-16;
    }
    &-name,
    &-note {
      font-weight: 600;
      font-size: 2rem;
      color: $color-11;
      white-space: nowrap;
    }
    &-note {
      font-size: 1.6rem;
    }
    &-range {
      font-size: 1.6rem;
      font-weight: 500;
      color: $color-12;
    }
    &-progress {
      font-weight: 500;
      font-size: 1.6rem;
      color: $color-12;
      display: flex;
      align-items: center;
      gap: 1rem;
      justify-content: center;
      &-negative {
        color: $color-13;
      }
      &-positive {
        color: $color-17;
      }
    }
  }
  &_title {
    align-items: start;
  }
}
