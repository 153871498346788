@media (max-width: 1500px) {
  .table-container {
    min-width: 1162px;
  }
}

.table {
  width: 100%;
  white-space: nowrap;

  &-firstline {
    display: flex;
    align-items: center;
    background: #f0f6f8;
    border: 1px solid #d8ecf3;
    border-radius: 10px;

    height: 53px;
    max-width: 1555px;

    &-nameStudent {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 36px;
      width: 17%;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 25px;
      color: #1b8392;
    }

    &-devoir {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: #1b8392;
      background: #f3f6f7;
      width: 20%;
    }

    &-test {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: 53px;
      background: #d8ecf3;
      border: 1px solid #d8ecf3;
      width: 13%;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #1b8392;
    }

    &-moyenne {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      color: #fff;
      height: 53px;
      background: #1b8392;
      border: 1px solid #d8ecf3;
      width: 13%;
    }

    &-rang {
      display: flex;
      justify-content: center;
      width: 10%;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #1b8392;
    }

    &-line {
      width: 1px;
      height: 100%;
      background-color: #d8ecf3;
    }
  }

  &-secondline {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 53px;
    max-width: 1555px;
    border-bottom: 1px solid #f0f6f8;

    &-nameStudent {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 36px;
      width: 17%;
    }

    &-devoir {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 10%;
    }

    &-test {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: 53px;
      width: 13%;
    }

    &-moyenne {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 53px;
      width: 13%;
    }

    &-rang {
      display: flex;
      justify-content: center;
      width: 10%;
      font-family: Poppins;
      font-size: 16px;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: center;
      color: #1b8392;
    }
  }

  &-main {
    overflow-y: scroll;
    max-height: 600px;

    &::-webkit-scrollbar {
      width: 10px;
      display: block;
    }

    &::-webkit-scrollbar-thumb {
      background: #d9d9d9;
      border-radius: 5px;
      display: block;
    }

    &::-webkit-scrollbar-track {
      display: block;
    }

    &-student {
      display: flex;
      align-items: center;

      &-lines {
        display: flex;
        align-items: center;
        margin: 21px 0;
      }

      &-name {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 25px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 30px;
        width: 17%;
        margin-left: 16px;

        &-link {
          display: flex;
          align-items: center;
          gap: 30px;
          color: #102528;
        }
      }

      &-DC1,
      &-DC2,
      &-DS1 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 15%;
      }

      &-test {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 13%;
      }

      &-moyenne {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 13%;
      }

      &-rang {
        display: flex;
        justify-content: center;
        width: 10%;
        color: #1b8392;
      }

      &-redNote {
        color: #f04438;
      }

      &-Redmoyenne {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
        background: #fee4e2;
        border-radius: 18px;
        color: #f04438;
        width: 100px;
        height: 38px;
        margin: 0 3.5%;
      }

      &-Orangemoyenne {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
        background: #fff4d3;
        border-radius: 18px;
        color: #fbb800;
        width: 100px;
        height: 38px;
        margin: 0 3.5%;
      }

      &-Greenmoyenne {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
        background: #d1fadf;
        border-radius: 18px;
        color: #12b76a;
        width: 100px;
        height: 38px;
        margin: 0 3.5%;
      }
    }

    &-line {
      height: 1px;
      width: 99%;
      background-color: #d8ecf3;
    }
  }
}

.ant-table-cell {
  white-space: nowrap;
}

:where(.css-dev-only-do-not-override-htwhyh).ant-table-wrapper .ant-table-header {
  width: fit-content;
}

.exam-grade {
  color: rgb(240, 68, 56);
  background-color: rgb(254, 228, 226);
  text-align: center;
  padding: 1.3rem 3rem;
  border-radius: 3rem;
  font-size: 1.9rem;
  width: fit-content;
}

.table-bulletin-heading {
  font-weight: 700;
}

.css-pdct74-MuiTablePagination-selectLabel {
  font-size: 2rem !important;
}

.css-16c50h-MuiInputBase-root-MuiTablePagination-sel {
  font-size: 2rem !important;
}

.avatar {
  width: 42px !important;
  margin: 0 auto;
  object-fit: cover;
  border-radius: 50%;
  height: 42px !important;
}

.table-bulletin {
  border-collapse: collapse;
  width: 100%;
  overflow-x: auto;

  tbody {
    tr {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      cursor: pointer;
      border-right: 1px solid #fbfdfdbd !important;

      &:hover {
        background: #dae2e232 ;
      }

      border-bottom: 1px solid #d8ecf3 !important;
    }
  }
  th,
  td {
    border: none;
    padding: 8px;
    text-align: center;
text-transform: capitalize;
    &:last-child,
    &:first-child {
      border-right: none !important;
      width:50px ;
      padding-right: 25px;  
    }
    &:last-child{
      width:10% !important ;
    }
    &:nth-child(2) {
      text-align: start !important;
     
        padding: 0 !important;
        width: 20% !important ;
      
    }
  }

  &-th {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 2rem;
    color: #1b8392;
    background-color: #f3f6f6;
    border-right: 1px solid #d8ecf3 !important;

    &:last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      border-right: none !important;
    }

    &:first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
    &-avg {
      background-color: #1b8392;
      color: white;
    }
  }

  &-heading {
    display: flex;
    flex-direction: column;
    gap: 10px;

    p {
      font-weight: 500;
      font-size: 1.4rem;
    }
  }
}

td {
  font-weight: 500;
}
.bulletin-rang{
  color:$color-2
}