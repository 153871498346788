.profile-container {
  display: flex;
  flex-direction: row;
  gap: 8%;
  padding: 24px 90px 14px 64px;
  width: 100%;
  overflow: auto;
  height: 90%;
  @media only screen and (max-width: 1000px) {
    flex-direction: column;
    padding: 24px 10px 14px 14px;
  }
  & > .profile-security {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px 25px;
    row-gap: 40px;
    height: fit-content;
    background: white;
    box-shadow: 0px 0px 15px rgba(210, 209, 209, 0.6);
    border-radius: 15px;
    @media only screen and (max-width: 321px) {
      padding: 20px 15px;
    }
  }
}
.profile-edit {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px 25px;
  gap: 20px;
  height: fit-content;
  background: white;
  box-shadow: 0px 0px 15px rgba(210, 209, 209, 0.6);
  border-radius: 15px;
  @media only screen and (max-width: 420px) {
    padding: 20px 10px;
    align-items: center;
  }
  & > .teacher-profile-form {
    width: 100%;
  }
}
.profile-edit-title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #1b8392;
  text-align: center;
}
.profile-edit-save-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  width: 192px;
  height: 55px;
  background: #1b8392;
  // cursor: pointer;
  border-radius: 10px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  align-self: flex-end;
  @media only screen and (max-width: 315px) {
    width: 100%;
  }
}
.profile-edit-save-btn[disabled='disabled'],
.profile-edit-save-btn:disabled {
  background: #99c6d3;
  cursor: not-allowed;
  pointer-events: none;
}
//****--------------------/*****

.profile-security {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  & > .profile-security-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    display: flex;
    align-items: center;
    color: #1b8392;

    @media only screen and (max-width: 300px) {
      transform: translate(-7%, 0px);
    }
  }
  & > .profile-security-img {
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: space-between;
    align-items: center;
    height: 210px;
    @media only screen and (max-width: 400px) {
      height: 190px;
    }
    & > .blue-cloud {
      z-index: 1;
      transform: translate(0%, 0);
      width: 104% !important;
    }
    & > .security-img {
      z-index: 10;
      transform: translate(0, -100%);
      width: 85%;
    }
  }
  & > .profile-security-edit-password-btn {
    width: 100%;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #f04438;
    border: 2px solid #f04438;
    border-radius: 10px;
    padding: 20px 15px 20px 15px;
    background-color: white;
    cursor: pointer;
  }
}
//*------POPUPPP Edit Password

.edit-password-popup-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 12px;
  & > .white-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;

    width: 100%;
    height: 51px;
    border: 1px solid #177c9a;
    border-radius: 8px;
    background-color: white;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;

    color: #177c9a;
  }
  & > .blue-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;
    width: 100%;
    height: 51px;
    background: #177c9a;
    border-radius: 8px;
    border: 0 !important;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: white;
  }
}
.se-dialog-content {
  border-radius: 14px !important;
}
.border-left-cont{
  border-left: 4px solid #99c6d3;
  border-radius: 5px;
  padding:5px 10px 5px 15px;
}
.ant-menu-light{
  z-index: 10000000000 !important;
}
.ant-layout-sider{
  z-index: 100 !important;

}