.input-number-static {
  white-space: nowrap;
  color: #ffffff;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;

  padding: 13px 22px 13px 22px;
  background: #99c6d3;
  border-radius: 5px;
  &--disabled {
    background: #1b8392;
  }
}
.input-number-stat {
  .ant-input-number {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 13px 22px;
    gap: 9px;

    background: #ffffff;

    border: 1px solid #1b8392;
    border-radius: 5px;

    color: #1b8392;
  }
}
