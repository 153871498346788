.error-admin {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 10px;
    background-color: $color-14;
    border-radius: 8px;

    padding: 1.3rem;

    p {
        font-size: 1.6rem;
        color: $color-13;
    }

    svg {
        width: 30px;
    }
}

.page {
    .error-admin {

        max-width: 400px;
    }
}