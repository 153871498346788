.undefined-route {
  svg {
    height: 90vh;
    width: 95vw;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .auth-link-account-verification {
    border: 1px solid rgb(27, 131, 146, 0.3);
    border-radius: 12px;
    padding: 12px;
  }
}
