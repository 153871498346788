.green-cercle {
  background-color: #9dd600;
  border-radius: 50%;
  width: 287px;
  height: 287px;
  width: 200px;
  height: 200px;
  position: absolute;
  transform: translate(25%, -20%);
}
.blue-cercle {
  background-color: #0095ad;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  position: absolute;
}
.auth-layout-corner {
  position: absolute;
  top: -76px;
  left: -80px;
  @media only screen and (max-width: 820px) {
    left: -15%;
  }
  @media only screen and (max-width: 590px) {
    left: -20%;
  }
  @media only screen and (max-width: 500px) {
    left: -25%;
  }

  @media only screen and (max-width: 390px) {
    left: -30%;
  }
}
