@import '../../../assets/styles/abstracts/colors';
.component-title {
  display: flex;
  flex-wrap: wrap-reverse;
  align-items: center;
  justify-content: center;
  &-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1.1rem;
    & h2 {
      color: #727272;
      font-family: 'Poppins';
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
  &-under {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
.title_container {
  display: flex;
  z-index: 9;
  flex-direction: column;
  gap: 1.5rem;
}
