.devoir {
  &-container {
    display: flex;
    gap: 50px;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 100%;
  }

  &-line {
    width: 10px;
    height: 100%;
    background-color: #fff4d3;
  }

  &-note {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #1b8392;
  }

  &-line {
    width: 2px;
    height: 100%;
    background-color: #fff4d3;
  }

  &-devoir {
    display: flex;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #727272;

    &-nameDate {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &-date {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: #b5b5b5;
  }

  &-notePourcentage {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &-pourcentage {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
    line-height: 43px;
    display: flex;
    align-items: center;
    text-align: center;
    gap: 8px;

    &-green {
      color: #12b76a;
      .devoir-pourcentage-IMGred {
        display: none;
      }
    }

    &-red {
      color: #f04438;
      .devoir-pourcentage-IMGgreen {
        display: none;
      }
    }
  }

  &-rang {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: #99c6d3;
  }

  &-ViewExamen {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 10px;
    gap: 15px;
    width: 116.4px;
    height: 36.66px;
    background: #1b8392;
    color: #fff;
    border-radius: 12.4957px;
  }
}
