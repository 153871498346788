.customized-header {
  margin: 30px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  margin-top: 2rem;
  width: 100%;
  padding: 0 55px 40px;
  gap: 20px;

  @media only screen and (max-width: 668px) {
    gap: 10px;
  }

  &>.customized-header-title {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    color: #1b8392;
    text-transform: capitalize;
    width: 30rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &>.customized-header-btns {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 11px;
    justify-content: center;



    .ant-select-selector {
      height: 40px !important;
      padding-top: 0.7rem !important;
    }

    &>.header-btn-white {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 8px 15px;
      background: white;
      border-radius: 8px;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #1b8392;
      border: 1px solid #1b8392;
      border-radius: 8px;

      @include mq(844px, max) {
        width: 100%;
      }
    }

    &>.header-btn-blue {
      all: unset;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 5px;
      padding: 8px 20px;
      background: #1b8392;
      border-radius: 8px;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #ffffff;

      @include mq(844px, max) {
        width: 100%;
      }
    }
  }
}

.header-btn-white-container {
  @include mq(844px, max) {
    width: 100%;
  }
}

.header-btn-white {
  all: unset;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 15px;
  background: white;
  border-radius: 8px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #1b8392;
  border: 1px solid #1b8392;
  border-radius: 8px;
  gap: 15px;
}

button {
  white-space: nowrap !important;
}

.ant-picker {
  width: 100% !important;
}

.filter-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  padding: 1rem;
  gap: 20px;
  border: 1px solid rgba(27, 131, 146, 1);
  color: #1b8392;
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 600;
}

.filter-intern {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  gap: 20px;
  width: 100%;
}

.student-list-pagination {
  .ant-select-selection-item {
    margin-top: 1rem;
  }
}

.customized-header-title {
  white-space: nowrap;
}