.switcher {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1rem 1.375rem;
  gap: 1rem;
  width: 100%;
  background: #99c6d3;
  border-radius: 8.12rem;
  margin-bottom: 3rem;
  @media (max-width: 650px) {
    padding: 0.5rem 1rem;
    button  {
      height: 5rem;
    }
  }
  .selected {
    background: #1b8392;
  }
}

@media (max-width: 481px) {
  .signUpForm {
    .switcher {
      margin: 1.5vh 0 3.5vh 0;
    }
  }
}

.loginForm,
.signUpForm,
.forgotPasswordForm,
.resetPasswordForm,
.verificationEmailForm,
.verificationCompteForm,
.acceptInvitationForm,
.updateProfileInfoCard,
.editPasswordCard,
.results {
  button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.62rem 0.92rem;
    gap: 1rem;
    background: #1b8392;
    border-radius: 0.62rem;
    border: none;
    cursor: pointer;
    margin-bottom: 0.625rem;

    &:hover {
      opacity: 0.5;
    }

    span {
      height: 3.18vh;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 1rem;
      display: flex;
      align-items: center;
      text-align: center;
      color: #ffffff;
    }
  }
}

.forgotPasswordForm {
  button {
    margin-top: 5.64vh;
  }
}

.switcher {
  button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    width: 100%;
    height: 6.4rem;
    background: transparent;
    border-radius: 3.125rem;
    border: none;
    cursor: pointer;
    margin-bottom: 0;

    span {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 2.5rem;
      display: flex;
      align-items: center;
      text-align: center;
      text-transform: capitalize;
      color: #ffffff;
    }
  }
}

.acceptInvitationForm {
  button {
    width: 56.3%;
    margin-top: 1.9vh;
  }
}

.updatePasswordCard {
  button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.25rem 1.56rem;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 0px 15px rgba(210, 209, 209, 0.6);
    border-radius: 15px;
    border: none;
    outline: 1px solid #f04438;
    cursor: pointer;

    span {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 1rem;
      color: #f04438;
    }
  }
}

.updateProfileInfoCard {
  button {
    width: 26.66%;
  }
}

.editPasswordCard {
  button {
    width: 100%;
    margin-bottom: 0;
  }

  button.editPassword-Annuler {
    background-color: transparent;
    outline: 1px solid #1b8392;

    span {
      color: #1b8392;
    }
  }
}

.matiereCard {
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 10px;
    width: 100%;
    height: 31px;
    background: #1b8392;
    border-radius: 8px;
    border: none;

    span {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 0.93rem;
      color: #ffffff;
    }
  }
}

.results {
  button {
    align-items: center;
    padding: 0.62rem 0.93rem 0.62rem 1.25rem;
    gap: 0.81rem;
    height: 2.5rem;
    width: 12.87rem;
    border-radius: 0.5rem;

    span {
      font-weight: 400;
      font-size: 0.87rem;
    }
  }
}

.ResultExamLine {
  button {
    flex-direction: row-reverse;
    width: 7.27rem;

    i {
      display: flex;
      width: 1.025rem;
      height: 1.025rem;

      svg {
        display: flex;
        width: 100%;
      }
    }
  }
}
