.overlay {
  width: 100vw;
  height: 100vh;
  background-color: #413c3c52;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 910;
}

.CancleExam {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    gap: 50px;
    padding: 30px 40px;
    background: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 12px;
    width: 60rem;
    z-index: 920;
    @media screen and (max-width: 600px) {
      width: 50rem;
    }
  }

  &-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #177c9a;
    font-size: 20px;
    line-height: 30px;

    & img {
      background-color: #ffffff;
      cursor: pointer;
    }
  }

  &-main {
    width: 100%;
    color: #959595;
    font-size: 15px;
    line-height: 25px;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  &-buttons {
    gap: 13px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-non {
      background: #177c9a;
      color: #ffffff;
      border-radius: 8px;
      width: 213px;
      height: 51px;
    }
    &-oui {
      border-radius: 8px;
      width: 213px;
      height: 51px;
      background: #ffffff;
      border: 1px solid #177c9a;
      color: #177c9a;
    }
  }
}
