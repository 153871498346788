.nofollow {
  display: flex !important;
  justify-content: space-around !important;
}

.ant-pagination-item {
  padding-top: 3.8px !important;
  a {
    font-family: 'Source Sans Pro' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 20px !important;
    color: #b5b5b5 !important;
    padding: 0 !important;
    margin: 0 !important;
    height: 30px !important;
  }
}
.ant-pagination-item.ant-pagination-item.ant-pagination-item-1.ant-pagination-item-active a {
  display: flex;
  justify-content: center;
}

.ant-pagination-item-active {
  border: none !important;
  a {
    font-family: 'Source Sans Pro' !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 20px !important;
    color: #1b8392 !important;
    padding: 0 !important;
    margin: 0 !important;
    display: flex;
    align-items: center !important;
    text-align: center !important;
    border: none !important;
    background: #f0f6f8 !important;
    border-radius: 50% !important;
    padding: 0 !important;
    margin: 0 !important;
    height: 100% !important;
  }
}
