@import './components/sessionCard/sessionCard';
@import './components/examCorrection/examCorrection';
@import './components/button/button';
.main_page-bulletin {
  padding: 0 2rem;
}
.bulletin {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: start;
  gap: 8rem;

  padding: 1rem;
  width: 100%;

  @include mq($contentHeader_breakpoint_small_screen, max) {
    flex-direction: column;
  }
  &-footer {
    display: flex;
    width: 100%;
    position: sticky;
    left: 0;
    align-items: center;
    text-align: center;

    justify-content: flex-end;
    padding-bottom: 3rem;
    gap: 1rem;
    padding: 4rem 0px;
    @include mq($bulletin_exams_breakpoint, max) {
      flex-direction: column;
    }
    &_grade {
      color: #fff;
      background-color: $color-2;
      border-radius: 15px;
      font-weight: 500;
      padding: 10px;
      text-align: center;
      font-size: 1.8rem;
      @include mq($bulletin_exams_breakpoint, max) {
        width: 100%;
      }
    }
    &_range {
      padding: 10px;
      text-align: center;
      background: $color-23;
      border-radius: 15px;
      font-weight: 500;
      font-size: 1.8rem;
      display: flex;
      align-items: center;
      color: #ffffff;
      @include mq($bulletin_exams_breakpoint, max) {
        width: 100%;
      }
    }
  }
}
.main_page-bulletin {
  width: 100%;
  height: 100%;
  overflow-x: auto;

  display: flex;
  flex-direction: column;
  gap: 2rem;

  .contentHeader {
    position: sticky;
    left: 0;
  }
}
.loader {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.student-info{
  display: flex;
  position: sticky;
  left: 0;
  align-items: center;
  gap: 1rem;
  &-pic{
    width: 7.8rem;
    height: 7.8rem;
    object-fit: cover;
  border-radius: 50%;
  }
  &-desc{
    display: flex;
    flex-direction: column;
    &-name{
      font-size: 2.5rem;
      font-weight: 600;
      color: $color-2;
    }
    &-class{
      font-size: 1.6rem;
      font-weight: 400;
      color: $color-11;
    }
  }
}
.main_page-bulletin{
  .navigation-links{
    position: sticky;
    left: 0;
  }
}
.main_page-bulletin{
  &::-webkit-scrollbar {
  display: block;
  background-color: transparent;
  height: 6px;
  width: 6px;
}
&::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
  border-radius: 10px;
}
&::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
}
}