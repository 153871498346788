.ixamee-sigle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 4%;
  height: 100vh;
  width: 40%;
  @media only screen and (max-width: 1000px) {
    width: 0;
    display: none;
  }
  & > .ixamee-logo {
    width: 78.48%;
    max-width: 330px;
    @media only screen and (max-width: 1000px) {
      width: 0;
      display: none;
    }
    @media only screen and (max-width: 1241px) {
      width: 78.48%;
    }
    & > img {
      width: 100%;
    }
  }
  & > .ixamee-sigle-description {
    display: flex;
    flex-direction: column;
    align-items: center;
    @media only screen and (max-width: 1000px) {
      width: 0;
      display: none;
    }
    @media only screen and (max-width: 1241px) {
    }
    & > .ixamee-sigle-description-second-line {
      display: flex;
      gap: 10px;
    }
  }
}
.ixamee-sigle-description-type-one {
  font-family: Poppins;
  font-size: 28px;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: center;
  color: #4c4c4d;
  @media only screen and (max-width: 1241px) {
    font-size: 26px;
    font-weight: 600;
    line-height: 50px;
  }
}
.ixamee-sigle-description-two {
  font-family: Poppins;
  font-size: 26px;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: center;
  color: #1b8392;
  @media only screen and (max-width: 1241px) {
    font-size: 26px;
    font-weight: 600;
    line-height: 48px;
  }
}
