.studentBulletin {
  &-title {
    display: flex;
    justify-content: space-between;
    margin-left: 10px;

    &-bigTitle {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      color: #1b8392;
      padding-bottom: 12px;
    }

    &-titlePath {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #999999;
    }
  }
}

.ant-input {
  color: #1b8392;
  height: 50px !important;
  border-radius: 10px;
}

.ant-input::placeholder {
  color: #1b8392;
}

.anticon-search {
  color: #1b8392;
  margin: 0 5px;
}
