.main_layout {
  display: flex !important;
  flex-direction: row !important;
  justify-content: flex-start !important;
  width: 100% !important;
}
.main_layout__container {
  display: flex !important;
  flex-direction: column !important;
  justify-content: flex-start !important;
  width: 100%;
  height: 100vh !important;
}
