.css-1p3m7a8-multiValue {
  background: #f0f6f8 !important;
  border-radius: 8px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  padding: 0px 7px !important;
  gap: 5px !important;
}
.css-wsp0cs-MultiValueGeneric {
  font-family: 'Poppins' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 15px !important;
  color: #727272 !important;
}

.css-1dimb5e-singleValue {
  font-family: 'Poppins' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 15px !important;
  line-height: 22px !important;
  display: flex !important;
  align-items: center !important;
  color: #727272 !important;
  border: 0 !important;
  width: 200px !important;
}

.css-tj5bde-Svg {
  fill: #1b8392 !important;
}
.css-8mmkcg {
  fill: #1b8392 !important;
}
.css-9jq23d {
  font-family: 'Poppins' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 15px !important;
  line-height: 22px !important;
  display: flex !important;
  align-items: center !important;
  color: #727272 !important;
  border: 0 !important;
  width: 200px !important;
}
