.examens {
  &-header {
    display: flex;
    padding: 0 14px 19px 5px;
    justify-content: space-between;

    &-leftSide {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 12px;
    }

    &-rightSide {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
    }
  }

  &-title {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #1b8392;
  }

  &-path {
    font-family: 'Poppins';
    font-size: 15px;
    line-height: 20px;
    color: #727272;
  }

  &-search-button,
  &-filter-button {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #99c6d3;
    border: 1px solid #99c6d3;
    background: #fff;
  }

  &-add-exam {
    color: #fff;
    background: #1b8392;
    width: 172px;
    height: 40px;
  }
}

.btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 15px;
  border-radius: 8px;
  white-space: nowrap;
  gap: 20px;
  cursor: pointer;
}
.import-btn {
  border: 1px solid #177c9a;

  display: flex;
  align-items: center;
  text-align: center;

  color: #177c9a;
}
