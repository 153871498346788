.admin-line {
  border-bottom: 1px solid #d9d9d9;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: Hug (60px);
  padding: 13px 20px;

  &-label {
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: $color-11;
  }
  &-container {
    display: flex;
    align-items: center;
    gap: 10px;
    &-img {
      border-radius: 50%;
      height: 40px;
      width: 40px;
    }
  }
  &-btns {
    display: flex;
    gap: 8px;
  }
}
