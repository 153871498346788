.page {
  display: flex;
  flex-direction: column;
  padding: 3rem;
  height: -webkit-fill-available;
  gap: 3rem;
  justify-content: center;
  padding: 3rem;

  &__header {
    display: flex;
    justify-content: space-between;
    padding-top: 4rem;

    &-right {
      display: flex;
      gap: 1rem;
      align-items: center;

      @include mq(584px, max) {
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        *> {
          width: 100%;
        }
      }
    }

    @include mq(584px, max) {
      width: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 10rem;
      gap: 1rem;

      *> {
        width: 100%;
      }
    }

  }

  &-title {
    font-size: 2.5rem;
    font-weight: 600;
    color: $color-2;
  }

  &_title {
    font-size: 2.2rem;
    font-weight: 600;
    text-transform: capitalize;
    color: $color-11;
  }
}

.show-teacher {
  &-image {
    display: flex;
    align-items: center;
    gap: 10px;

    &-name {
      color: $color-11;
      font-size: 1.3rem;
      font-weight: 500;
    }

    &-desc {
      display: flex;
      flex-direction: column;
    }

    &-email {
      font-size: 1.35rem;
      font-weight: 400;
      color: $color-11;
    }
  }

  &-data {
    display: flex;
    flex-direction: column;
    gap: 2.2rem;

    @include mq(470px, max) {
      gap: 4rem;
    }

    &-field {
      flex-wrap: wrap;
      display: flex;
      align-items: center;
      color: $color-11;
      font-size: 1.5rem;
      gap: 10px;

      &-label {
        color: $color-2;
        font-weight: 500;
        font-size: 1.7rem;
      }

      &-subject {
        font-size: 1.5rem;
        border: 1px solid $color-9;
        border-radius: 100px;
        padding: 3px 7px;
      }
    }
  }
}

.select {
  label {
    display: none !important;
  }
}

.pop-up {
  .select-async {
    width: 100%;
    max-width: 800px;
  }
}