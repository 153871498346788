.teacherData {
  &-title p {
    font-family: Poppins;
    font-size: 45px;
    font-weight: 600;
    line-height: 68px;
    letter-spacing: 0em;
    color: #1b8392;
    margin-bottom: 100px;
    @include mq(1143px, max) {
      font-size: 40px;
    }

    @include mq(926px, max) {
      font-size: 30px;
    }

    @include mq(485px, max) {
      font-size: 25px;
    }

    @include mq(321px, max) {
      font-size: 20px;
    }
  }
}

.children {
  &-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 100px;

    @include mq(321px, max) {
      gap: 50px;
    }
  }

  &-select {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    &-name {
      font-family: 'Poppins';
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;
      color: #727272;
    }

    &-imputImg {
      position: relative;
    }

    &-img {
      position: absolute;
      margin-top: 15px;
      z-index: 1;
      margin-left: 8px;
    }

    &-input {
      width: 950px;
      z-index: 0;
      border-radius: 10px;
      height: 55px;

      @include mq(1555px, max) {
        width: 850px;
      }

      @include mq(1400px, max) {
        width: 700px;
      }

      @include mq(1160px, max) {
        width: 600px;
      }

      @include mq(990px, max) {
        width: 450px;
      }

      @include mq(755px, max) {
        width: 550px;
      }

      @include mq(560px, max) {
        width: 400px;
      }

      @include mq(425px, max) {
        width: 300px;
      }

      @include mq(310px, max) {
        width: 250px;
      }
    }

    &-button {
      width: 950px;
      height: 50px;
      padding: 10px 15px 10px 15px;
      border-radius: 10px;
      background: #99c6d3;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: center;
      color: #ffffff;
      margin-top: 60px;
      margin-bottom: 30px;

      @include mq(1555px, max) {
        width: 850px;
      }

      @include mq(1400px, max) {
        width: 700px;
      }

      @include mq(1160px, max) {
        width: 600px;
      }

      @include mq(990px, max) {
        width: 450px;
      }

      @include mq(755px, max) {
        width: 550px;
      }

      @include mq(560px, max) {
        width: 400px;
      }

      @include mq(425px, max) {
        width: 300px;
      }

      @include mq(310px, max) {
        width: 250px;
      }
    }
  }
}

.navigation-wrapper {
  display: grid;
}

.dots {
  display: flex;
  padding: 10px 0;
  justify-content: center;
}

.dot {
  border: none;
  width: 10px;
  height: 10px;
  background: #d0d5dd;
  border-radius: 50%;
  margin: 0 5px;
  padding: 5px;
  cursor: pointer;
}

.dot.active {
  background: #1b8392;
}

.children-select-inputImg {
  .ant-select-single .ant-select-selection-placeholder,
  .ant-select-single .ant-select-selection-item {
    margin: 0.5rem 3.5rem;
  }

  .ant-select-multiple .ant-select-selection-placeholder {
    inset-inline-start: 45px !important;
  }
  .ant-select-singdevas5le .ant-select-selection-overflow,
  .ant-select-multiple .ant-select-selection-overflow {
    margin-left: 35px !important;
  }
}
.children-select-button {
width: 90%;

  background-color: rgba(23, 124, 154, 1) !important;
}
.children-select-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.navigation-wrapper {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  .ant-select-selector {
    height: 100% !important;
    & .ant-select-selection-search-input {
      height: 100% !important;
    }
  }
  * {
    max-width: 700px;
  }
}
.form{
  display:flex ;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  width: 90%;
}
