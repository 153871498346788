@import './contentHeaderBreakPoint';
.contentHeader {
  display: flex;
  width: 100%;
  justify-content: space-between;

  align-items: center;
  transition: all 0.3s ease;
  gap: 3rem;
  padding-top: 3rem;
  &-title {
    transition: all 0.3s ease;
    color: $color-2;
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 600;
  }

  &-leftSide {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
  }

  &-subTitle {
    transition: all 0.3s ease;
    color: $color-10;
    font-size: 1.7rem;
    font-style: normal;
    font-weight: 400;
  }
}
