.value-card {
  display: flex;
  gap: 1rem;
  align-items: center;
  flex-grow: 1;
  min-width: 179px;
  padding: 0px 1rem;
  border-radius: 3rem;
  padding: 10px;
  height: 93px;

  &_content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0.8rem;

    &--title {
      font-weight: 500;
      font-size: 2.3rem;
    }

    &--value {
      font-size: 2.3rem;
      font-weight: 500;
    }
  }

  &_logo {
    &_parent {
      /* fi-rr-graduation-cap */

      border-radius: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0.5rem;
      gap: 1rem;

      width: 6rem;
      height: 6rem;
    }
  }
}
