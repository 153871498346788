.auth-illustration {
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 63%;
    @media only screen and (max-width: 1000px) {
      width: 0;
      display: none;
    }
    @media only screen and (max-width: 1241px) {
      width: 78.48%;
    }
  }
}
