@import './assets/styles/abstracts';
@import './assets/styles/base';
@import './assets/scss/utilities/mixins';
@import './assets/scss/utilities/variables';
@import 'components/components';
@import './features';
@import './layouts/Sidebar/sidebar';
@import './layouts/Header/search';
@import './layouts/DashboardLayout/dashBoardLayout';
@import './features/Bulletin';
@import './pages/settings/settings';
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  font-family: 'Poppins';
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  outline: none;
  border: none;
  letter-spacing: 0.6px;
}

html {
  font-size: 50.5%;
  scroll-behavior: smooth;
  scroll-padding-top: 8rem;
}

*::-webkit-scrollbar-track {
  display: none;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background: none;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #d9d9d9;
}

*::-webkit-scrollbar-corner {
  background-color: transparent;
}

.auth-container-login .auth-form-login {
  &::-webkit-scrollbar {
    display: none;
  }
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
}

.auth_loader {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    background-color: transparent !important;
  }
}

.ant-select-selector {
  overflow: hidden;
  min-height: 52px !important;
}

.dashboard {
  .ant-select-selector {
    overflow: hidden;
    min-height: unset !important;

  }
}

.bulletin-main {
  .ant-select-selector {
    overflow: hidden;
    min-height: unset !important;

  }
}