@use 'src/assets/styles/base';
@use 'src/assets/styles/abstracts' as *;
.auth-container-verification {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100vh;
  width: 100%;
  align-items: center;
  background: #f0f6f8;
  .auth-form-verification {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 52.8%;
    height: 100%;
    background: $color-0;
    border-radius: 0px 100px 100px 0px;
    @media only screen and (max-width: 1241px) {
      width: 85%;
    }
    @media only screen and (max-width: 1000px) {
      width: 100%;
      border-radius: 0;
    }
    .form-container-verification {
      display: flex;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 64.5375%;
      row-gap: 50px;
      @media screen and (max-width: 1000px) {
        width: 80%;
      }
      .form-verification {
        display: flex;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        row-gap: 84px;
        .button-holder-verification {
          display: flex;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          row-gap: 10px;
          .echec-verification {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            display: flex;
            flex-direction: row;
            align-items: center;
            text-align: center;
            color: #727272;
            .auth-link-verification p {
              font-family: 'Poppins';
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              display: flex;
              align-items: center;
              text-align: center;
              color: #1b8392;
            }
            button {
              color: #1b8392;
              font-size: 16px;
              font-weight: 500;
              @media screen and (max-width: 1000px) {
                padding: 5px;
              }
            }
          }
        }
      }
      .form-title-verification {
        width: 100%;
        .h1-verification {
          font-family: 'Poppins';
          font-style: normal;
          white-space: nowrap;
          font-weight: 600;
          font-size: 45px;
          @include mq(1120px, max) {
            font-size: 6vw;
          }

          line-height: 68px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          color: #1b8392;
          width: 100%;
        }
      }
      .form-description-verification {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 17px;
        line-height: 24px;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        gap: 0.5rem;
        color: #727272;
        @media screen and (max-width: 1000px) {
          font-size: 12px;
        }
        .mailP {
          color: #102528;
          font-size: 18px;
          @media screen and (max-width: 1000px) {
            font-size: 13px;
          }
        }
      }
    }
  }
}

/***************/
.auth-link-verification:link,
.auth-link-verification:visited {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;
  color: #1b8392;
}

.auth-link-verification:hover {
  color: #99c6d3;
}
.auth-link-verification-disabled {
  cursor: wait;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.character {
  border: none;
  font-size: 20px;
  border-radius: 8px;

  color: #272729;
  background-color: #f6f5fa;
}
.vi__wrapper {
  width: 100%;
  height: 4.5vw;

  @include mq(1001px, max) {
    height: 12vw;
    width: 72vw;
  }
}
.form-verif_email {
  width: 100%;
}
.formik-login {
  gap: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.container {
  height: 100%;
  width: 100%;
  justify-content: space-around;
  gap: 2rem !important;
}
.character {
  border: 2px solid #d9d9d9;
  border-radius: 20px;
  display: flex;
  font-size: 30px;
  display: flex;
  padding: 10px;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;
  @include mq(1120px, max) {
    font-size: 5vw;
  }
  align-items: center;
  justify-content: center;
  color: #727272;
  background-color: white;
}
.character.error {
  background-color: #fff4f3;
  border: 2px solid #f04438;
  color: #f04438;
  outline: 0 !important;
}
.character.success {
  border: 2px solid #12b76a;
  background: #f4fff9;
  color: #12b76a;
  outline: 0 !important;
}
.character--inactive {
  font-size: 30px;
  @include mq(1120px, max) {
    font-size: 5vw;
  }

  opacity: 0.6;
  color: rgba(217, 217, 217, 1);
  font-weight: 600;
}

.vi__character {
  border-radius: 20px;
  display: flex;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;
  &--selected {
    outline: 2px solid #727272 !important;
    border: 0;
  }
  @media screen and (max-width: 1000px) {
    border-radius: 16px;
  }
  @media screen and (max-width: 600px) {
    border-radius: 8px;
  }
}
