@use 'src/assets/styles/base';
@use 'src/assets/styles/abstracts' as *;
.auth-button {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 13px 15px;
  gap: 16px;

  background: #1b8392;
  border-radius: 10px;
  font-family: 'Poppins';
  font-style: normal;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  text-align: center;
  color: $color-0;
  border: 0;
  cursor: pointer;
}
.auth-button:active {
  background: #99c6d3;
}
.auth-button:disabled {
  background: #99c6d3;
  cursor: not-allowed;
}
