.auth-alert {
  width: 100%;
  display: inline-block;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  border-radius: 8px;
  background-color: #fff4f3;
  padding: 7px 15px 7px 15px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #f04438;
  .alert-icon {
    z-index: 10;
    width: 20px;
    height: 20px;
    margin-right: 8.92px;
    transform: translate(0%, 20%);
  }
}
