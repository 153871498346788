.button {
  &-container {
    display: flex;
    gap: 15px;
    justify-content: flex-end;

    .ant-select-selector {
      height: 40px !important;
      padding-top: 0.3rem !important;
    }
  }

  &-pdf {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 15px 10px 20px;
    gap: 13px;
    background: #99c6d3;
    border-radius: 8px;
    color: #fff;
  }

  &-select-semestre,
  &-select-class {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 15px;
    gap: 13px;
    width: 157px;
    height: 40px;
    background: #ffffff;
    border: 1px solid #d0d5dd;
    border-radius: 8px;
  }
}

.ant-input {
  color: #1b8392;
}

.ant-input::placeholder {
  color: #1b8392;
}

.anticon-search {
  color: #1b8392;
  margin: 0 5px;
}
