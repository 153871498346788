.question {
  &-container {
    @include mq(460px, max) {
      padding: 10px;
      flex-direction: column-reverse;
    }

    margin: 20px 0 20px 5%;
    padding: 0 20px;
    border: 1px solid #d9d9d9;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    position: relative;
  }

  &-arabic {
    margin: 20px 5% 20px 0;
    padding: 0 20px;
    border: 1px solid #d9d9d9;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    position: relative;
  }

  &-input {
    width: 90%;
    min-height: 78px;

    &-full {
      width: 100%;
      font-size: 20px;
    }
  }

  &-note {
    display: flex;
    border: 1px solid #1b8392;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    padding: 13px 22px;
    background: none;
  }

  &-input-note {
    background: none;
    max-width: 50px;
    color: #1b8392;
  }

  &-pts {
    color: #1b8392;
    font-size: 16px;
  }

  &-rightside {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &-remove {
    background: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    background: none;
    cursor: pointer;
  }
}

.medium {
  margin-left: 10%;
}

.medium-arabic {
  margin-right: 10%;
}

.small {
  margin-left: 20%;
}

.small-arabic {
  margin-right: 20%;
}

.add-button {
  position: absolute;
  left: -0.81%;
  width: 23px;
  height: 23px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px;
  gap: 10px;
  cursor: pointer;
  background: #cfe8e6;
  border-radius: 100px;
}

.add-button-arabic {
  position: absolute;
  right: -0.81%;
  width: 23px;
  height: 23px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px;
  gap: 10px;
  cursor: pointer;
  background: #cfe8e6;
  border-radius: 100px;
}

.ant-collapse-header {
  display: flex !important;
  align-items: center !important;
  padding: 0 10px !important;
  border-radius: 7px !important;
}

.question-input {
  display: flex;
  align-items: center;

  td,
  th {
    border: 1px solid #e1e1e1;
    padding: 0.4em;
    background-clip: padding-box;
  }
  img {
    max-width: 100% !important;
  }
}

.question-note {
  & > input::-webkit-outer-spin-button,
  & > input::-webkit-inner-spin-button {
    display: none;
  }
}
