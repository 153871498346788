.error_message-field {
  min-height: 35px;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // border-radius: 8px;
  // background: #fff4f3;
}

.form-login {
  display: flex;
  flex-direction: column;
}

// .error {
//   color: var(--13, #f04438);
//   font-size: 15px;
//   font-style: normal;
//   font-weight: 400;
//   border-radius: 8px;
//   background: var(--14, #fff4f3);
//   padding: 5px 45px;
//   width: 100%;
//   position: relative;
//   // &::before {
//   //   content: url('../../../../assets/icons/error.svg');
//   //   position: absolute;
//   //   top: 4px;
//   //   left: 15px;
//   // }
// }

.errors {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;

  &_el {
    display: flex;
    gap: 7px;
  }
}
