.cards-list-empty-message-container {
  padding-left: 3rem;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 175.5%;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 351px) {
    padding: 16px 10px 14px 18px;
    justify-content: center;
  }
}

.cards-list-empty-message {
  color: #727272;
}

.cards-list-empty-message-link {
  all: unset;
  cursor: pointer;
  color: #1b8392;
}