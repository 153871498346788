.form-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  row-gap: 10px;
  width: 100%;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #727272;
  .form-group-input:focus-within {
    border: 1.5px solid #177c9a;
    border-radius: 10px;
    color: #727272;
    input {
      color: #3f3f3f;
    }
  }
  #error {
    border: 1.5px solid #f04438;
  }
  .form-group-input {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    gap: 17.5px;
    border: 1.5px solid #d9d9d9;
    border-radius: 10px;

    input {
      border: 0;
      width: 100%;
      font-family: 'Poppins';
      font-weight: 400;
      font-size: 16px;
      line-height: 34px;
      color: #565656;
      outline: none;
    }
    input:focus {
      border: 0;
    }
  }
  .input-field-icon {
    fill: #727272;

    width: 45px;
    height: 21.33px;
    z-index: 10;
  }
  .eye-icon {
    cursor: pointer;
    width: 30px;
    z-index: 10;
  }
}
.form-group-title {
  display: flex;
  .red-star {
    color: red;
  }
}
