.bulletin {
  &-container {
    margin: 35px 29px -8px 60px;

    height: 90%;
    @include mq(770px, max) {
      margin: 8px;
    }
  }

  &-title {
    &-bigTitle {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      color: #1b8392;
      padding-bottom: 12px;
    }

    &-titlePath {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #999999;
    }
  }

  &-main {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    &-empty {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 19px;
      line-height: 175.5%;
      color: #727272;
    }

    &-notEmpty {
      display: none;
    }

    &-tableEmpty {
      display: none;
    }

    &-table {
      margin-top: 22px;
      overflow-x: scroll;
      overflow-y: hidden;
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: space-between;
      &::-webkit-scrollbar {
        width: 10px;
        display: block;
      }

      &::-webkit-scrollbar-thumb {
        background: #d9d9d9;
        border-radius: 5px;
        display: block;
      }

      &::-webkit-scrollbar-track {
        display: block;
      }
      div {
        &::-webkit-scrollbar {
          width: 10px;
          display: block;
        }

        &::-webkit-scrollbar-thumb {
          background: #d9d9d9;
          border-radius: 5px;
          display: block;
        }

        &::-webkit-scrollbar-track {
          display: block;
        }
      }
    }
  }
}
