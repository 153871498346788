.photo-select {
  &_label {
    color: $color-15;
    padding-bottom: 0.8rem;
  }
  &_photos {
    max-width: 500px;
    border: 1px solid #e4e4e4;
    display: grid;
    grid-template-columns: repeat(6, auto);
    padding: 3rem;
    gap: 1.5rem;
    border-radius: 8px;
  }
}
.photo {
  width: 55px;
  height: 55px;
  position: relative;
  border-radius: 50%;
  &-file{
  top: 0;
  border-radius: 50%;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  cursor: pointer;
  opacity: 0;

  }
  img{
    width: 50px;
    height:50px;
    object-fit: cover;
    border-radius: 50%;
  }
  &-selected {
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    &::after{
      content: url("../../../../assets/icons/check.svg");
      top: 3px;
      right: 6px;
      transform: scale(1.8);
      position: absolute;
    }
  }
  &-deleteBtn{
    cursor: pointer;
    position: absolute;
    width: 20px;
    height: 20px;
    bottom: 10px;
    left: -9px;
    transform: scale(0.2);

  }
}
.pop-up{
  .ant-form-item-label{
    display: none;
  }
}