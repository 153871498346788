@use 'src/assets/styles/base';
@use 'src/assets/styles/abstracts' as *;
.MuiToolbar-root {
  .MuiAutocomplete-root {
    .MuiInputBase-root {
      padding: 0;
      padding-right: 24px;
      & > .MuiInputBase-input {
        margin-left: 42px;
        min-width: fit-content;
        // @include mq(713px, max) {
        //   min-width: auto;
        // }
      }
      & > .MuiOutlinedInput-notchedOutline {
        border: none;
        outline: none;
      }
    }
  }
}

.searchbar {
  & > .MuiFormControl-root {
    & > .MuiInputBase-root {
      padding-right: 0 !important;
      // & > .MuiInputBase-input {
      // }
      & > .MuiAutocomplete-endAdornment {
        display: none;
      }
    }
  }
}

.search-main {
  border-radius: 10px !important;
  width: 40% !important;
}
.autocomplete-item {
  display: flex;
  flex-direction: column;
  padding: 4px;
  border-bottom: 1px solid #d6e0ec;
  width: 100%;
  transition: all 300ms ease;
  text-decoration: none;
  color: black;
  & > .title {
    font-size: 14px;
    color: #000;
  }
  & > .subtitle {
    font-size: 12px;
    color: #a9a9ae;
    transition: all 300ms ease;
  }
  &:hover > .title {
    color: #18699c;
  }
}

.css-11b3ww9-MuiPaper-root-MuiAppBar-root {
  position: initial !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  align-items: center !important;
  padding: 15px 60px !important;
  background: #f0f6f8 !important;
  box-shadow: none !important;
  border: none !important;
  //border-radius: none !important;
  height: 75px !important;
  margin: 0 !important;
  z-index: 900 !important;
}
.css-hyum1k-MuiToolbar-root {
  justify-content: space-between !important;
  width: 100% !important;
  padding: 0 !important;
  margin: 0 !important;
}
///******SEARCHBAR
.css-1q6at85-MuiInputBase-root-MuiOutlinedInput-root {
  direction: rtl !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  align-items: center !important;
  padding: 6px 15px !important;
  gap: 33px !important;
  width: 100% !important;
  height: 32px !important;
  background: $color-0 !important;
  border-radius: 10px !important;
}
.css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input {
  direction: ltr !important;
  padding: 0 !important;
  font-family: 'Source Sans Pro' !important;
  font-style: italic !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 18px !important;
  color: #b5b5b5;
}
.css-k7mmqj-MuiFormControl-root-MuiTextField-root {
  width: 40% !important;
}
.css-ittuaa-MuiInputAdornment-root {
  margin: 0 !important;
}
.css-h4y409-MuiList-root {
  padding: 5px !important;
  width: 131px !important;
  height: 74px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 5px !important;
  border: 0 !important;
  box-shadow: 0 !important;
}
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  padding: 6px !important;
  width: 100% !important;
}
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root a {
  text-decoration: none;
  width: 100% !important;
  font-family: 'Source Sans Pro' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  display: flex !important;
  align-items: center !important;
  color: #727272 !important;
  padding: 0 !important;
  margin: 0 !important;
  &:hover {
    background: #f0f6f8;
    border-radius: 5px;
  }
}
