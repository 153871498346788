.pop-up {
  background-color: white;
  min-width: 450px;
  padding: 3rem;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 4rem;

  @include mq(470px, max) {
    min-width: unset;
    gap: 6rem;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100vw;
    top: 0;
    left: 0;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.134);
    z-index: 1000000000 !important;

  }

  &-header {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    &-title {
      white-space: nowrap;
      font-weight: 500;
      font-size: 2.3rem;
      @include mq(340px, max) {
        font-size: 1.7rem;
      }
      color: #177c9a;
    }
  }
  .ant-form-item-required {
    display: none !important;
  }
  &-delete {
    display: flex;
    flex-direction: column;
    gap: 5px;
    &-desc {
      color: $color-15;
      font-size: 1.5rem;
      font-weight: 400;
    }
    &-btns {
      display: flex;
      gap: 1rem;
    }
  }
  &-del {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
}
