@use 'src/assets/styles/base';
@use 'src/assets/styles/abstracts' as *;
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.archives-container {
  height: 100%;
  margin: 20px 0 0 10px;
}
.archives-header {
  display: flex;
  padding: 0 14px 19px 5px;
  justify-content: space-between;
  align-items: flex-end;
  height: 8.8125rem;
}

.archives-header-left-side {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  // height: 40px !important;
}
.archives-header-left-side-path {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #727272;
}
.archives-header-left-side-title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #1b8392;
  margin-top: 20px;
}
.archives-header-right-side {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 11px;
  width: 100%;
}
@media only screen and (max-width: 445px) {
  .archives-header-right-side {
    display: flex;
    flex-direction: column;
  }
}
.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.blue-btn {
  background-color: #1b8392;
  padding: 8px 6px;
  gap: 10px;
  border-radius: 8px;
  border: 0;
  color: $color-0;
  width: 89px;
}
.add-archive-btn {
  background-color: #1b8392;
  padding: 8px 6px;
  gap: 10px;
  border-radius: 8px;
  border: 0;
  color: $color-0;
  width: 100%;
  min-width: 150px !important;
  height: 40px;
}
.search-archive-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 15px;
  background-color: white;
  border: 1px solid #1b8392;
  border-radius: 8px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #1b8392;
  width: 100%;
  gap: 6px;
}
.filter-archive-btn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 15px;
  background-color: white;
  border: 1px solid #1b8392;
  border-radius: 8px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #1b8392;
  width: 100%;
  gap: 5px;
}

.archives-list{
  gap: 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
  @include mq(512px,max){
    display: flex !important;
    flex-direction: column;
    width: 72%;
  }
}
.archive-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 24px 21.5px 23px 20.11px;
  max-width: 351.52px;
  row-gap: 14px;
  background: #f3f6f7;
  border: 1px dashed #1b8392;
  border-radius: 12px;
  & > .archive-card-top {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0px;
    width: 100%;
    & > .archive-card-top-details {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      height: 100%;
      & > .archive-title {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 25px;
        display: flex;
        align-items: center;
        color: #727272;
      }
    }
  }
  & > .archive-card-center {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0px;
    width: 100%;
    & > .archive-card-center-details {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      height: 100%;
      row-gap: 5px;
      & > .archive-title {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        display: flex;
        align-items: center;
        color: #727272;
      }
      & > .archive-description {
        font-family: 'Poppins';
        font-style: italic;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        display: flex;
        align-items: center;
        color: #727272;
      }
    }
    & > .archive-card-center-correction-average {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 15.0651px;
      line-height: 15px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-between;
      text-align: center;
      color: $color-0;
      padding: 10px 5.5px;
      background: #1b8392;
      border-radius: 11.2988px;
    }
  }
  & > .archive-card-bottom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;
    gap: 25.11px;
    width: 100%;
    height: 7.53px;
    background: white;
    border-radius: 15.0651px;
    & > .archive-card-bottom-loading {
      height: 7.53px;
      background: #1b8392;
      border-radius: 15.0651px;
    }
  }
}
///////ADD archive INPUTS/////
.archives-adding {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 38px 24px 31px 20px;
  border-bottom: 1px solid #e4e7ec;
}
.archive-input {
  display: flex;
  flex-direction: column;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #898989;
  row-gap: 10px;
  input {
    height: 60px;
    border: 1px solid #e8e8e8;
    border-radius: 8px;
    padding: 18px;
  }
  input::placeholder {
    font-family: 'Poppins';
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: #a8a8a8;
  }
  input:hover {
    background-color: #f2f2f2;
    color: #000000;
  }
}

.save_archive {
  height: 44px;
  width: 9%;
  transform: translate(0%, 70%);
  display: flex;
  justify-content: center;
}
input[type='file'] {
  display: none;
}
.archive-img_input {
  display: flex;
}

@media only screen and (max-width: 719px) {
  .archives-header {
    display: flex;
    flex-direction: row;
    align-items: center;

    justify-content: space-between;
    row-gap: 10px;
    height: 8.8125rem;
    border-bottom: 1px solid #e8e8e8;
  }
  .archives-adding {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 38px 24px 31px 20px;
    border-bottom: 1px solid #e4e7ec;
    row-gap: 10px;
  }
  .save_archive {
    height: 44px;
    width: 100%;
    transform: translate(0%, 20%);
    display: flex;
    justify-content: center;
  }
}
@media only screen and (max-width: 635px) {
  .archives-header {
    display: flex;
    flex-direction: column;
    height: 14rem;
  }
  .archives-header-right-side {
    flex-direction: column;
  }
}
@media only screen and (max-width: 445px) {
  .archives-header {
    display: flex;
    flex-direction: column;
  }
}
@media only screen and (max-width: 340px) {
  .archives-header {
    display: flex;
    flex-direction: column;
  }
}
/////////////////
///
///
///
$cubic-out: cubic-bezier(0.32, 2.04, 0.85, 0.54);
$cubic-in: cubic-bezier(0.72, 1.2, 0.71, 0.72);
$nunito: 'Nunito', sans-serif;
$roboto: Roboto, sans-serif;

$cyan: #00bcd4;
$yellow: #ffeb3b;
$grey: #9e9e9e;

$shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16), 0 2px 8px 0 rgba(0, 0, 0, 0.12);

// Kebab styling

.kebab {
  cursor: pointer;
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  padding: 0 3px;
  figure {
    width: 6px;
    height: 6px;
    border-radius: 5px;
    background: $cyan;
    margin: 3px 0;
  }
}

.middle {
  cursor: pointer;
  transition: all 0.25s $cubic-in;
  transform: scale(1);
  position: relative;
  box-shadow: 0 0.1px 0.1px 0 rgba(0, 0, 0, 0.16), 0 0.1px 0.3px 0 rgba(0, 0, 0, 0.12);
  -webkit-filter: blur(0.1px);
  filter: blur(0.1px);
}

// Other styling

.dropdown {
  position: absolute;
  z-index: 900;
  right: -111px;

  transition: all 0.25s ease-out;
  transform: scale(0);
  background: $color-0;
  border-radius: 5px;
  width: 131px;

  padding: 5px;
  // box-shadow: $shadow;
  & > li {
    display: block;
    width: 100%;
    &:hover {
      background: #f0f6f8;
      border-radius: 5px;
    }
    & > button {
      all: unset;
      text-decoration: none;
      width: 100%;
      padding: 5px;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #727272;
      cursor: pointer;

      &:hover {
        text-decoration: none;
      }
    }
  }
  &:hover {
    ul {
      background: #f0f6f8;
      border-radius: 5px;
      transform: scale(1);
    }
  }
}

.dropdown.active {
  transform: scale(1);
  transition: all 0.25s cubic-bezier(0.5, 1.8, 0.9, 0.8);
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.5);
  @include mq(415px, max) {
    left: -100px;
  }
}
//////////////DELETE POPUP
///
///
.delete-archive-popup-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  & > .delete-archive-popup-message {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    display: flex;
    align-items: center;
    color: #959595;
  }
  & > .delete-archive-popup-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 12px;
    & > .white-btn {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 10px;
      gap: 10px;

      width: 100%;
      height: 51px;
      border: 1px solid #177c9a;
      border-radius: 8px;
      background-color: white;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;

      color: #177c9a;
    }
    & > .red-btn {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 10px;
      gap: 10px;
      width: 100%;
      height: 51px;
      background: #f04438;
      border-radius: 8px;
      border: 0 !important;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: white;
    }
  }
}

////////ARCHIVE POPUP
.archive-archive-popup-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  & > .archive-archive-popup-message {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    display: flex;
    align-items: center;
    color: #959595;
  }
  & > .archive-archive-popup-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 12px;
    & > .white-btn {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 10px;
      gap: 10px;

      width: 100%;
      height: 51px;
      border: 1px solid #177c9a;
      border-radius: 8px;
      background-color: white;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;

      color: #177c9a;
    }
    & > .blue-btn {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 10px;
      gap: 10px;
      width: 100%;
      height: 51px;
      background: #177c9a;
      border-radius: 8px;
      border: 0 !important;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: white;
    }
  }
}
/////ModalDesign
.ant-modal-title {
  font-family: 'Poppins' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 30px !important;
  color: #1b8392 !important;
}
.formik-modal-group {
  display: flex;
  flex-direction: column;
  row-gap: 7px;
  align-items: flex-start;
  .formik-modal-label {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-transform: capitalize;
    color: #999999;
    &-lb {
      width: fit-content;
      white-space: nowrap;
    }
    #red-star {
      color: red;
    }
  }
}
.css-dev-only-do-not-override-mxhywb .ant-input-number:hover {
  border-inline-end-width: 0 !important;
}

.formik-modal-add-archive-style {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 44px;
  gap: 25px;
  & > .add-archive-style {
    display: flex;
    justify-content: space-around;
    gap: 10px;
    align-items: center;
    width: 100%;
    border-radius: 10px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    height: 100%;
    color: #999999;
    background: $color-0;
    border: 1px solid #e4e4e4;
    border-radius: 10px;
    cursor: pointer;
  }
  & > .active {
    background: #f0f6f8;
    border: 1px solid #1b8392;
    border-radius: 10px;
    color: #1b8392 !important;
  }
}

.choose-archive {
  margin-left: 31px;
  display: flex;
  width: fit-content;
  border-bottom: 1px solid #99c6d3;
  gap: 22px;

  & > .archive-not-active {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    align-items: center;
    color: #99c6d3;
    background: white;
    padding: 10px;
  }
  & > .archive-active {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    align-items: center;
    color: #1b8392;
    border-bottom: 2px solid #1b8392;
    // filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.1));
    background: white;
    padding: 10px;
  }
}
