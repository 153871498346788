@use 'src/assets/styles/base';
@use 'src/assets/styles/abstracts' as *;
.auth-container-account-verification {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100vh;
  background: #f0f6f8;
  .auth-form-account-verification {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 52.8%;
    height: 100%;
    background: $color-0;
    border-radius: 100px 0px 0px 100px;
    @media only screen and (max-width: 1241px) {
      width: 80%;
    }
    @media only screen and (max-width: 1000px) {
      width: 100%;
      border-radius: 0;
    }

    .form-container-account-verification {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      width: 64.5375%;
      row-gap: 40px;
      @media only screen and (max-width: 540px) {
        width: 90%;
      }
      /* ----- */
      .form-title-account-verification {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 100%;
        .h1-account-verification {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 600;
          font-size: 45px;
          line-height: 68px;
          color: #177c9a;
          @media only screen and (max-width: 1125px) {
            font-weight: 600;
            font-size: 45px;
            line-height: 68px;
          }
          @media only screen and (max-width: 600px) {
            font-weight: 600;
            font-size: 45px;
            line-height: 56px;
          }
          @media only screen and (max-width: 525px) {
            font-weight: 600;
            font-size: 40px;
            line-height: 50px;
          }
        }
      }

      /* ----- */
      .formik-account-verification {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        row-gap: 40px;
        .form-account-verification {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;

          width: 100%;
          height: 230px;
          @media only screen and (max-width: 573px) {
            row-gap: 0;
          }

          .under-form-account-verification {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 16.312%;

            .stay-connected-account-verification {
              font-family: 'Poppins';
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 25px;
              color: #727272;
            }
          }
        }
      }
    }
  }
}

/***********************/

/* --------------------- */

/* --------------------- */
/***********************/

.button-holder-account-verification {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  row-gap: 10px;

  .resend-account-verification p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #727272;
  }
}

.auth-link-account-verification:link,
.auth-link-account-verification:visited {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;
  color: #1b8392;
}

.auth-link-account-verification:hover {
  color: #99c6d3;
}
