/* ------ colors ----------
-----------------------------*/
$pure_white: #ffff;
$pure_black: #000000;

$darker_white: #f8f8fd; // background, sidebar_items
$lighter_black: #0d0e0f;
$grey: #a2a2a7;
$lighter_grey: #dadade;

$blue: #2ba7df;
$darker_blue: #18698d;
$lighter_blue: #b1e4e9;
$light_cyan: #dff1f5;
$lighter_blue: #b1e4f9;

$grey_dark: #494949;
$blue_dark: #2ba7df80;
$white1: #d3d3d3;
$white2: #dddddd;
$white3: #ebebf3;
$white4: #f8f9fa;
$grey1: #a2a2a7;
$grey3: #6c757d;
$blue1: #daeef7;
$blue3: #18698d;
$purple1: #e6e9fb;

$red_opac: #f5e1e1;
$orange_oopac: #f2e2d9;

$orange: #ec5542;
$orange2: #fe726e; // progress
$orange3: #e94a14; // badge

$purple: #4a3c98; // progress
$green: #50bf88; // progress
$yellow: #ffda2d; // progress
$lighter_green: #3dec37;

/* ------ fonts ----------
-----------------------------*/
// @import url('https://fonts.googleapis.com/css2?family=Tajawal&display=swap');
// $tajwal_font: 'Tajawal';
