@use 'src/assets/styles/base';
@use 'src/assets/styles/abstracts' as *;
.btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;
  min-width: 14rem;
  border-radius: 8px;
 
}
