.ant-menu-vertical {
  background-color: #1b8392 !important;
  z-index: 1000000000000000000000;
}

.admin-table {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2rem;
  animation: enter 0.4s ease-in-out;
  &-tab {
    overflow: scroll;
    max-height: calc(100vh - 246px);
    border: 1px solid var(--8, #d0d5dd);
    box-shadow: 0px 4px 15px 0px rgba(210, 209, 209, 0.6);
    border-radius: 4px;
    height:-webkit-fill-available ;
  }
  &-pagination{
    // position: absolute;
    // bottom: 1rem;
    // right: 3rem;
  }
}
@keyframes enter {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
