.changeDirection {
  direction: rtl;
}
.iscorreting {
  margin-top: 20rem !important;
}
.conctructionExamens {
  &-contianer {
    padding: 25px;
    height: 90vh;
  }
  &__content {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
  }
  &-scroll {
    gap: 20px;
    width: 100%;
    height: 90vh;
    display: flex;
    flex-direction: column;
    overflow: auto;
    margin-top: 13rem;
    padding-bottom: 35rem;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  &-header {
    direction: ltr !important;
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
    box-shadow: 0px 5px 5px 0px #1b829235;
    border-radius: 0 0 12px 12px;
    position: absolute;
    width: -webkit-fill-available;
    right: 0;
    background: white;
    z-index: 10;
    padding: 0 2rem 2rem 2rem;
    @include mq(460px, max) {
      align-items: center;
      flex-direction: column;
    }
    &__student {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      p {
        color: #494949;
        font-size: 14px;
        font-weight: 400;
      }
    }
    &-leftSide {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 12px;

      @include mq(460px, max) {
        align-items: center;
      }
    }

    &-rightSide {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
      justify-content: space-between;
      gap: 9px;
    }
  }

  &-title {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #1b8392;
  }

  &-path {
    font-family: 'Poppins';
    font-size: 15px;
    line-height: 20px;
    color: #727272;
  }

  &-cancle-button {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #f04438;
    border: 1px solid #f04438;
    background: #fff;
    display: flex;
    align-items: center;
    gap: 20px;
  }

  &-import-button,
  &-save-exam {
    color: #fff;
    background: #1b8392;
    height: 40px;
    gap: 7px;
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 12px;
  }

  &-add-exercice {
    width: 100%;
    height: 43px;
    background: #1b8392;
    color: #fff;
  }
}

.indexLoading {
  opacity: 0.5;
  z-index: 10;
  pointer-events: none;
}

.ant-spin {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 50;
}

.btn {
  @include mq(460px, max) {
    width: 100%;
  }
}

.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  @include mq(460px, max) {
    height: 100px;
  }
}

.exercice-title {
  @include mq(460px, max) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;

    padding-bottom: 8px;
  }
}

.conctructionExamens-add-exercice {
  background: transparent;
  color: #d9d9d9;
  width: fit-content;
  margin: 6px auto;

  &:hover {
    opacity: 0.7;
    box-shadow: none !important;
    transform: none !important;
  }
}

.score-exam {
  width: 125px;
  height: 40px;
  gap: 7px;
  text-align: center;
  display: flex;
  font-size: 2rem;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  background-color: $color-20;
  color: $color-17;
  border: rgb(57, 151, 57) 1px dashed;

  &-red {
    color: $color-13;
    background-color: $color-14;
    border-color: red;
  }
}

.add-ex {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #1b8392;
  padding: 2rem;
  z-index: 3;
  border-radius: 50%;

  &:hover {
    transition: all 0.1s ease;
    box-shadow: 0px 4px 6px 0px rgba(50, 50, 93, 0.11), 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
    transform: scale(1.01);
  }

  @include mq(580px, max) {
    display: none;
  }
}

.ex-exam {
  position: relative;
}
