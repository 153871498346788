.kebab {
  cursor: pointer;
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  padding: 0 3px;

  figure {
    width: 6px;
    height: 6px;
    border-radius: 5px;
    background: $cyan;
    margin: 3px 0;
  }
}

.middle {
  cursor: pointer;
  transition: all 0.25s $cubic-in;
  transform: scale(1);
  position: relative;
  box-shadow: 0 0.1px 0.1px 0 rgba(0, 0, 0, 0.16), 0 0.1px 0.3px 0 rgba(0, 0, 0, 0.12);
  -webkit-filter: blur(0.1px);
  filter: blur(0.1px);
}

// Other styling

.filter-exams-choices {
  position: absolute;
  z-index: 900;
  top: 130px;
  right: 225px;
  transition: all 0.25s ease-out;
  transform: scale(0);
  background: $color-0;
  border-radius: 5px;
  width: 220px;
  padding: 5px;
  padding-bottom: 0;

  &>li {
    display: block;
    width: 100%;
    margin-bottom: 5px;

    &:hover {
      background: #f0f6f8;
      border-radius: 5px;
    }

    &>button {
      all: unset;
      text-decoration: none;
      width: 85%;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #727272;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 10px 15px;
      gap: 13px;
      background: #ffffff;
      border: 1px solid #e4e4e4;
      border-radius: 8px;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

.filter-exams-choices.active {
  margin-top: 2rem;
  transform: scale(1);
  transition: all 0.25s cubic-bezier(0.5, 1.8, 0.9, 0.8);
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.5);
}

.dropdown-filter-exams-per-class {
  transition: all 0.25s ease-out;
  transform: scale(0);
  background: $color-0;
  border-radius: 5px;
  box-shadow: -2px 2px 15px #e3e3e6;
  border-radius: 5px;
  padding: 5px;
  height: 112px;
  overflow: scroll;

  &>li {
    display: block;
    width: 100%;
    margin-bottom: 5px;

    &:hover {
      background: #f0f6f8;
      border-radius: 5px;
    }

    &>button {
      all: unset;
      text-decoration: none;
      width: 100%;
      padding: 5px;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #727272;
      cursor: pointer;
      display: flex;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &:hover {
    ul {
      background: #f0f6f8;
      border-radius: 5px;
      transform: scale(1);
    }
  }
}

.filter-choosed {
  background: #1b8392 !important;
  border-radius: 5px;

  &>button {
    color: #ffffff !important;
  }
}

.dropdown-filter-exams-per-class.active {
  transform: scale(1);
  transition: all 0.25s cubic-bezier(0.5, 1.8, 0.9, 0.8);
}

.dropdown-btns {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 9px;
}

.filter-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 6px;
}

.dropdown-btns {
  button {
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }

    border-radius: 5px;
    width: 100%;
    padding: 2px 8px;
    text-align: left;
  }
}

.dropdown-filter-selected-btn {
  background-color: rgba(27, 131, 146, 1);

  &:hover {
    background-color: rgba(27, 131, 146, 0.7) !important;
  }
}

.info {
  color: #016569;
  font-size: 1.4rem;
  font-weight: 300;
}