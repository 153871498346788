@use 'src/assets/styles/base';
@use 'src/assets/styles/abstracts' as *;

.student-student-input {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0;
  padding-left: 1rem;
  background-color: white;
  border: 1px solid #1b8392;
  border-radius: 8px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #1b8392;
  width: 100% !important;
  height: 40px !important;
}

.studentsPage:has(.navigation-links) {
  .customized-header {
    padding-bottom: 0;
  }
}

[class^='ant-input'] [class^='ant-input'],
[class*=' ant-input'] [class^='ant-input'],
[class^='ant-input'] [class*=' ant-input'],
[class*=' ant-input'] [class*=' ant-input'] {
  border: none;
  padding: 1.8px;
  height: 100% !important;
}

[class^='ant-input'] [class^='ant-input'],
[class*=' ant-input'] [class^='ant-input'],
[class^='ant-input'] [class*=' ant-input'],
[class*=' ant-input'] [class*=' ant-input'] {
  height: 100% !important;
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.blue-btn {
  background-color: #1b8392;
  padding: 8px 6px;
  gap: 10px;
  border-radius: 8px;
  border: 0;
  color: $color-0;
  width: 89px;
}

.search-student-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 15px;
  background-color: white;
  border: 1px solid #1b8392;
  border-radius: 8px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #1b8392;
  width: 100%;
  gap: 6px;
}

///////ADD student INPUTS/////

.student-input {
  display: flex;
  flex-direction: column;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #898989;
  row-gap: 10px;

  input {
    height: 60px;
    border: 1px solid #e8e8e8;
    border-radius: 8px;
    padding: 18px;
  }

  input::placeholder {
    font-family: 'Poppins';
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: #a8a8a8;
  }

  input:hover {
    background-color: #f2f2f2;
    color: #000000;
  }
}

input[type='file'] {
  display: none;
}

$cubic-out: cubic-bezier(0.32, 2.04, 0.85, 0.54);
$cubic-in: cubic-bezier(0.72, 1.2, 0.71, 0.72);
$nunito: 'Nunito', sans-serif;
$roboto: Roboto, sans-serif;

$cyan: #00bcd4;
$yellow: #ffeb3b;
$grey: #9e9e9e;

$shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16), 0 2px 8px 0 rgba(0, 0, 0, 0.12);

// Kebab styling

.middle {
  cursor: pointer;
  transition: all 0.25s $cubic-in;
  transform: scale(1);
  position: relative;
  box-shadow: 0 0.1px 0.1px 0 rgba(0, 0, 0, 0.16), 0 0.1px 0.3px 0 rgba(0, 0, 0, 0.12);
  -webkit-filter: blur(0.1px);
  filter: blur(0.1px);
}

// Other styling

.dropdown {
  position: absolute;
  z-index: 900;
  right: 0;

  transition: all 0.25s ease-out;
  transform: scale(0);
  background: $color-0;
  border-radius: 5px;
  width: 131px;

  padding: 5px;

  // box-shadow: $shadow;
  & > li {
    display: block;
    width: 100%;

    &:hover {
      background: #f0f6f8;
      border-radius: 5px;
    }

    & > button {
      all: unset;
      text-decoration: none;
      width: 100%;
      padding: 5px;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #727272;
      cursor: pointer;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &:hover {
    ul {
      background: #f0f6f8;
      border-radius: 5px;
      transform: scale(1);
    }
  }
}

.dropdown.active {
  transform: scale(1);
  transition: all 0.25s cubic-bezier(0.5, 1.8, 0.9, 0.8);
}

/////ModalDesign
.ant-modal-title {
  font-family: 'Poppins' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 30px !important;
  color: #1b8392 !important;
}

.formik-modal-group {
  display: flex;
  flex-direction: column;
  row-gap: 7px;
  align-items: flex-start;

  .formik-modal-label {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-transform: capitalize;
    color: #999999;

    #red-star {
      color: red;
    }
  }
}

.css-dev-only-do-not-override-mxhywb .ant-input-number:hover {
  border-inline-end-width: 0 !important;
}

#invite-student-icon-invited {
  filter: invert(65%) sepia(53%) saturate(6908%) hue-rotate(122deg) brightness(101%) contrast(86%);
}

.table-title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #1b8392;
}

//////////////////UPLOAD IMAGE
///
.add-student-upload-img {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;

  .add-student-upload-img-details-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: #727272;
  }

  .add-student-upload-img-details-description {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 38px;
    display: flex;
    align-items: center;
    color: #727272;
  }
}

/////////Student List Style
///
.student-list-rang-and-pic-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;

  & > .student-list-rang {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;
    width: 40px;
    height: 21px;
    background: #d8ecf3;
    border: 1px solid #1b8392;
    border-radius: 10px;
    color: #1b8392;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
  }

  & > .student-list-profile-image {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.student-mail .ant-form-item-row .ant-form-item-label {
  display: none !important;
}

.status2,
.status1,
.status0,
.status3,
.status4,
.status5 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  flex-direction: row-reverse;
  border-radius: 16px;
  width: fit-content;
  padding: 0.5rem 1rem;
  font-size: 1.8rem;

  div {
    padding: 0rem 0.5rem;
  }

  article {
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }
}

.status3 {
  background-color: #ececec;

  div {
    color: #000000;
  }

  article {
    background-color: #000000;
  }
}

.status2 {
  background-color: #f4fff9;

  div {
    color: #12b76a;
  }

  article {
    background-color: #12b76a;
  }
}

.status1 {
  background-color: #fff4d3;

  div {
    color: #f69d16;
  }

  article {
    background-color: #f69d16;
  }
}

.status4 {
  background-color: #f4efff;

  div {
    color: #8862f5;
  }

  article {
    background-color: #8862f5;
  }
}

.status3 {
  background-color: #c8ebf1;

  div {
    color: #1773f3;
  }

  article {
    background-color: #00bce1da;
  }
}
.status5 {
  background-color: #e7e7e7;

  div {
    color: #727272;
  }

  article {
    background-color: #727272;
  }
}

.status0 {
  background-color: #fff4f3;

  div {
    color: #f04438;
  }

  article {
    background-color: #f04438;
  }
}

.classes {
  gap: 1rem;
  align-items: center;
  justify-content: start;
  height: 100%;

  .table-wrapper {
    // width: 100% !important;
  }

  .navigation-links {
    padding-left: 7rem;
    display: flex;
    gap: 1rem;
    width: 100%;

    a img {
      margin-left: 8px;
    }
  }

  .ant-table-content {
    border-radius: 12px 12px 0 0;
  }

  .ant-table-container {
    min-width: fit-content !important;
  }

  .ant-table-tbody {
    .ant-table-placeholder {
      z-index: 1 !important;
    }
  }

  .ant-table-wrapper .ant-table-tbody > tr > td {
    border-bottom: 1px solid #f0f0f0 !important;
  }

  .ant-table-empty .ant-table-container .ant-table-content .ant-table-tbody > tr > td {
    border-bottom: none !important;
  }
}

.student__trashSvg {
  width: 24px;
  height: 24px;
  margin: 1rem;
  animation: trashAnimation infinite ease;
  animation-duration: 1s;

  path {
    fill: rgb(253, 253, 253);
    stroke: rgb(242, 109, 109);
    cursor: pointer;
  }
}

@keyframes trashAnimation {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(5px);
  }

  100% {
    transform: translateY(0);
  }
}

.ant-table-cell {
  background: white;
}

.css-dev-only-do-not-override-htwhyh .ant-table-wrapper .ant-table-thead > tr > th,
:where(.css-dev-only-do-not-override-htwhyh).ant-table-wrapper .ant-table-thead > tr > td {
  background: $color-4 !important;
}

.students-list {
  padding: 0 2rem;
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: start;

  @media screen and (max-width: 1200px) {
    min-width: 90vw;
  }

  &::-webkit-scrollbar {
    &-trak {
      background: #f1f1f1;
    }

    &-thumb {
      background: #888;
    }
  }

  .ant-table {
    @media screen and (max-height: 1150px) {
      height: calc(75px * 10 + 55px);
    }

    @media screen and (max-height: 1050px) {
      height: calc(75px * 9 + 55px);
    }

    @media screen and (max-height: 990px) {
      height: calc(75px * 8 + 55px);
    }

    @media screen and (max-height: 890px) {
      height: calc(75px * 7 + 55px);
    }

    @media screen and (max-height: 820px) {
      height: calc(75px * 6 + 55px);
    }

    @media screen and (max-height: 760px) {
      height: calc(75px * 5 + 55px);
    }

    @media screen and (max-height: 700px) {
      height: calc(75px * 4 + 55px);
    }

    @media screen and (max-height: 650px) {
      height: calc(75px * 3 + 55px);
    }
  }
}

.addStudent--PopUp {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;

  .select-async {
    width: 100%;
    padding: 0;
  }

  .select-container {
    padding: 0;
  }

  &__link {
    width: 100%;
    text-align: right;
    color: #1b8392;
    font-weight: 500;
    cursor: pointer;
    margin-top: 1rem;
  }
}

.ant-modal-content {
  animation: Enter 0.5s ease-in-out;
}

.searchItem {
  display: flex;
  gap: 2rem;
  padding: 0.5rem 1rem;

  &--cordinate {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    p {
      font-family: 'Poppins';

      font-size: 14px;
      font-weight: 500;
      color: #201f1f;
    }
  }

  &--img {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.ant-table-row {
  animation: Enter 0.5s ease-in-out;
}

@keyframes Enter {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.correction_options {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  @include mq(455px, max) {
    flex-direction: column;
  }
}

.MuiBackdrop-root {
  background-color: transparent !important;
}

.MuiDialog-paper {
  box-shadow: none !important;
  filter: drop-shadow(0px 3px 5px rgba(26, 27, 27, 0.1));
}

.correction_option-note {
  input {
    width: max-content;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
}

.correction_option {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;

  &-title {
    color: $color-15;
    font-size: 12px;
    font-weight: 400;
  }

  &-div {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-2;
    padding: 10px;
    border-radius: 10px;
    gap: 1rem;
    background-color: #f0f6f8;
    font-size: 16px;
    font-weight: 500;
    width: 18rem;
    border: 1px solid transparent;
    max-width: 145px;
  }

  input {
    background-color: #f0f6f8;
  }
}

.correction_options {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px 5px 24px;
}

.options-sep {
  font-size: 20px;
  font-weight: 500;
  color: $color-2;
}

.sep {
  width: 100%;

  padding: 16px 24px 5px 24px;

  div {
    height: 1px;
    background-color: #d9d9d9;
  }
}

.title-case {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 1rem;
}

.correction_option-mark {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.pop-up-delete-btns.options {
  padding: 20px 24px;

  @include mq(455px, max) {
    flex-direction: column;
  }
}

.correction-option-selected {
  border: 1px solid #1b8392;
}

.student-list-pag {
  display: flex;
  flex-direction: column;

  width: 100%;
  gap: 1rem;
  justify-content: space-between;
  padding: 1rem 3rem;
}

.auth-button {
  margin-top: 1rem;
}

.pdf-button {
  margin-left: 1rem;

  svg {
    width: 30px;
    height: 30px;
  }
}

.btn:disabled {
  opacity: 0.8;
  cursor: not-allowed;

  &:hover {
    transform: none;
  }
}

.classes {
  .customized-header {
    .header-btn-white {
      @include mq(574px, max) {
        padding-left: 0;
        padding-right: 0;
      }
    }

    > div,
    > button {
      @include mq(574px, max) {
        width: 100% !important;
      }
    }
  }
}

.event-cont {
  display: flex;
  align-items: center;
  justify-content: start;
  height: 23px;
}

.classes {
  .ant-select-selector {
    min-height: unset !important;
  }
}

.dialog-csv {
  padding: 3rem;

  &-temp {
    font-size: 1.4rem;
    font-weight: 400;
    color: $color-15;
  }
}

.customizedHeader__dropDown {
  cursor: pointer;
  background: #1b8392;
  padding: 6.5px 10px;
  border-radius: 8px;
  color: white;
  font: normal 600 14px/20px normal;

  .ant-space {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__icon {
    padding-top: 3px;
  }
}
