@use 'src/assets/styles/base';
@use 'src/assets/styles/abstracts' as *;
.notification {
  @include mq(960px, max) {
    display: none;
  }
  & > button > span {
    & > .MuiAvatar-root {
      margin-right: 8px;
    }
    & > .texts {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: start;
      text-transform: capitalize;
      @include mq(960px, max) {
        display: none;
      }
      & > .title {
        font-size: 14px;
        line-height: 20px;
        color: $color-2;
        font-weight: 500;
      }
      & > .subtitle {
        font-size: 12px;
        line-height: 12px;
        color: $grey;
        font-weight: 400;
      }
    }
    & > .icon-holder {
      display: flex;
      justify-content: center;
      align-items: center;
      color: $color-2;
      margin-left: 4px;
    }
  }
  .notification-icon {
    opacity: 1;
    filter: invert(31%) sepia(72%) saturate(567%) hue-rotate(153deg) brightness(93%) contrast(88%);
  }
  .nb-notif {
    border-radius: 50%;
    border: 2px solid var(--unnamed-color-ffffff);
    background: #e94a14 0% 0% no-repeat padding-box;
    border: 2px solid $color-0;
    opacity: 1;
    text-align: center;
  }
  /*
  .notification-icon {
    color: #ec5542;
  }*/
}
