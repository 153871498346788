@use 'src/assets/styles/base';
@use 'src/assets/styles/abstracts' as *;
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.classes-container {
  height: 100%;

  display: flex;
  flex-direction: column;

  @include mq(888px, max) {
    align-items: center;
  }

  justify-content: space-between;
}
.input-error {
  color: red !important;
  border-color: red !important;
}
.classes-header {
  display: flex;
  padding: 0 14px 19px 5px;
  justify-content: space-between;
  align-items: flex-end;
  height: 8.8125rem;
  border-bottom: 1px solid #e8e8e8;
}

.classes-header-left-side {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: -2rem !important;
  padding: 0rem 0 0 3rem;
  gap: 10px;
}

.classes-header-left-side-path {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #727272;
}

.classes-header-left-side-title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #1b8392;
}

.classes-header-right-side {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 11px;
  width: 100%;
  //height: 40px !important;

  [class^='ant-input'] [class^='ant-input'],
  [class*=' ant-input'] [class^='ant-input'],
  [class^='ant-input'] [class*=' ant-input'],
  [class*=' ant-input'] [class*=' ant-input'] {
    height: 100% !important;
  }
}

@media only screen and (max-width: 445px) {
  .classes-header-right-side {
    display: flex;
    flex-direction: column;
  }
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.blue-btn {
  background-color: #1b8392;
  padding: 8px 6px;
  gap: 10px;
  border-radius: 8px;
  border: 0;
  color: $color-0;
  width: 89px;
}

.add-class-btn {
  background-color: #1b8392;
  padding: 8px 6px;
  gap: 10px;
  border-radius: 8px;
  border: 0;
  color: $color-0;
  width: 100%;
  min-width: 150px !important;
  height: 40px;
}

.search-class-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 15px;
  background-color: white;
  border: 1px solid #1b8392;
  border-radius: 8px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #1b8392;
  width: 100%;
}

.classes-list {
  overflow: scroll;

  article {
    padding: 0 67px;
    gap: 2rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 20px;
  }
}

.class-card {
  @include mq(420px, max) {
    min-width: 200px;
    width: 100%;
  }

  display: flex;
  cursor: pointer;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 24px 21.5px 23px 20.11px;
  row-gap: 14px;
  background: #f3f6f7;
  border-radius: 25px;

  & > .class-card-top {
    display: flex;
    flex-direction: row;
    height: -webkit-fill-available;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0px;

    width: 100%;

    & > .class-card-top-details {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      height: 100%;

      & > .class-title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 200px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 25px;
        color: #727272;
      }

      & > .class-description {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #727272;
      }
    }
  }
}

///////ADD class INPUTS/////
.classes-adding {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 38px 24px 31px 20px;
  border-bottom: 1px solid #e4e7ec;
}

.class-input {
  display: flex;
  flex-direction: column;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #898989;
  row-gap: 10px;

  input {
    height: 60px;
    border: 1px solid #e8e8e8;
    border-radius: 8px;
    padding: 18px;
  }

  input::placeholder {
    font-family: 'Poppins';
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: #a8a8a8;
  }

  input:hover {
    background-color: #f2f2f2;
    color: #000000;
  }
}

.save_class {
  height: 44px;
  width: 9%;
  transform: translate(0%, 70%);
  display: flex;
  justify-content: center;
}

input[type='file'] {
  display: none;
}

.class-img_input {
  display: flex;
}

@media only screen and (max-width: 719px) {
  .classes-header {
    display: flex;
    flex-direction: row;
    align-items: center;

    justify-content: space-between;
    row-gap: 10px;
    height: 8.8125rem;
    border-bottom: 1px solid #e8e8e8;
  }

  .classes-adding {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 38px 24px 31px 20px;
    border-bottom: 1px solid #e4e7ec;
    row-gap: 10px;
  }

  .save_class {
    height: 44px;
    width: 100%;
    transform: translate(0%, 20%);
    display: flex;
    justify-content: center;
  }
}

@media only screen and (max-width: 635px) {
  .classes-header {
    display: flex;
    flex-direction: column;
    height: 14rem;
  }

  .classes-header-right-side {
    flex-direction: column;
  }
}

@media only screen and (max-width: 445px) {
  .classes-header {
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (max-width: 340px) {
  .classes-header {
    display: flex;
    flex-direction: column;
  }
}

/////////////////
///
///
///
$cubic-out: cubic-bezier(0.32, 2.04, 0.85, 0.54);
$cubic-in: cubic-bezier(0.72, 1.2, 0.71, 0.72);
$nunito: 'Nunito', sans-serif;
$roboto: Roboto, sans-serif;

$cyan: #00bcd4;
$yellow: #ffeb3b;
$grey: #9e9e9e;

$shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16), 0 2px 8px 0 rgba(0, 0, 0, 0.12);

// Kebab styling

.kebab {
  position: relative;
  display: inline-block;
  box-sizing: border-box;

  button {
    background-color: transparent;
    padding: 6px;
    cursor: pointer;
  }

  figure {
    width: 6px;
    height: 6px;
    border-radius: 5px;
    background: $cyan;
    margin: 3px 0;
  }
}

.middle {
  cursor: pointer;
  transition: all 0.25s $cubic-in;
  transform: scale(1);
  position: relative;
  box-shadow: 0 0.1px 0.1px 0 rgba(0, 0, 0, 0.16), 0 0.1px 0.3px 0 rgba(0, 0, 0, 0.12);
  -webkit-filter: blur(0.1px);
  filter: blur(0.1px);
}

// Other styling

.dropdown {
  position: absolute;
  z-index: 900;
  right: 0;

  transition: all 0.25s ease-out;
  transform: scale(0);
  background: $color-0;
  border-radius: 5px;
  width: 131px;

  padding: 5px;

  // box-shadow: $shadow;
  & > li {
    display: block;
    width: 100%;

    &:hover {
      background: #f0f6f8;
      border-radius: 5px;
    }

    & > button {
      all: unset;
      text-decoration: none;
      width: 100%;
      padding: 5px;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #727272;
      cursor: pointer;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &:hover {
    ul {
      background: #f0f6f8;
      border-radius: 5px;
      transform: scale(1);
    }
  }
}

.dropdown.active {
  transform: scale(1);
  transition: all 0.25s cubic-bezier(0.5, 1.8, 0.9, 0.8);
  left: -100px;
}

//////////////DELETE POPUP
///
///
.delete-class-popup-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 25px;
  margin-top: 50px;

  & > .delete-class-popup-message {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    display: flex;
    align-items: center;
    color: #959595;
  }

  & > .delete-class-popup-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 15px;
    padding: 0 1rem;

    .btn {
      height: 45px !important;
    }

    & > .white-btn {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 10px;
      gap: 10px;

      width: 100%;
      height: 51px;
      border: 1px solid #177c9a;
      border-radius: 8px;
      background-color: white;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;

      color: #177c9a;
    }

    & > .red-btn {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 10px;
      gap: 10px;
      width: 100%;
      height: 51px;
      background: #f04438;
      border-radius: 8px;
      border: 0 !important;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: white;
    }
  }
}

////////ARCHIVE POPUP
.archive-class-popup-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 25px;
  padding-top: 50px;

  & > .archive-class-popup-message {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    display: flex;
    align-items: center;
    color: #959595;
  }
}

.archive-class-popup-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 15px;
  padding: 0 1rem;

  .btn {
    height: 45px !important;
  }

  & > .white-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;

    width: 100%;
    height: 51px;
    border: 1px solid #177c9a;
    border-radius: 8px;
    background-color: white;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;

    color: #177c9a;
  }

  & > .blue-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;
    width: 100%;
    height: 51px;
    background: #177c9a;
    border-radius: 8px;
    border: 0 !important;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: white;
  }
}

/////ModalDesign
.ant-modal-title {
  font-family: 'Poppins' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 30px !important;
  color: #1b8392 !important;
}

.class-card-top-details {
  gap: 3rem !important;
  justify-content: start !important;
}

.class_card-header-title {
  color: #1b8392;
  font-family: Poppins;
  font-size: 18px;
  white-space: nowrap;
  font-weight: 600;
  width: 16rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

/*********Popup*/
.ant-modal-close:hover {
  color: rgba(0, 0, 0, 0.45) !important;
  background-color: transparent !important;
  text-decoration: none;
}

.student-list-pagination {
  display: flex;
  justify-content: flex-end;
  bottom: 1rem;
  right: 1rem;
  align-self: flex-end;
  padding: 2rem;

  @include mq(813px, max) {
    position: unset;

    justify-content: unset;

    padding: 0;
  }

  @include mq(355px, max) {
    padding: 1rem;
  }
}

.button-container {
  @include mq(440px, max) {
    .ant-space {
      flex-direction: column;
      align-items: center;
      width: 100%;

      & > * {
        width: 100% !important;
      }

      .ant-select {
        width: 100% !important;
      }
    }
  }
}

.bulletin-title-bigTitle {
  @include mq(440px, max) {
    text-align: center;
  }
}

.ant-modal .ant-select-selector {
  padding: 10px 5px !important;
}
