.exercice {
  &-container {
    margin: 36px 0 27px 0;
    padding: 15px 15px 25px 15px;
    border: 1px solid #d9d9d9;
    border-radius: 7px;
  }

  &-title {
    border-radius: 7px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 17px 0 0;
    background: #f0f6f8;

    height: 64px;

    &-leftside {
      & input {
        background: #f0f6f8;
      }

      display: flex;
      gap: 29px;
    }

    &-input {
      display: flex;
      gap: 11px;
    }

    &-1 {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      color: #1b8392;
    }

    &-rightside {
      padding: 10px 40px;
      max-width: 200px;
      border-radius: 5px;
      background: #fff;
      color: #1b8392;
      border: 2px solid #1b8392;
    }

    &-note {
      max-width: 50px;
      font-size: 20px;
      background: #fff;
      color: #1b8392;
    }

    &-note::placeholder {
      color: #1b8392;
      font-size: 14px;
    }
  }

  &-add-question {
    background: #1b8392;
    color: #fff;
  }

  &-remove-btn {
    color: #d9d9d9;
    cursor: pointer;
    background: none;
    display: flex;
    align-items: center;
  }
}
.ant-input .ant-input-borderless {
  padding: 0 !important;
}
.ant-popover-arrow::after {
  background-color: #1b8392 !important;
}
