@use 'src/assets/styles/base';
@use 'src/assets/styles/abstracts'as *;

.sidebar {
  overflow: scroll;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0px !important;

  background: #1b8392;
  width: 260px;

  &>.header {
    text-align: center;
    padding: 25px 25px 0 25px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    &::before {
      content: '';
      // background: url('../../assets/img/header-mask.svg') no-repeat;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: -1;
      position: absolute;
    }
  }

  &>.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow: auto;
    height: 80%;
  }

  &>.content>.container {
    padding: 0 20px;
    width: 100%;
    flex-grow: 1;

    &>ul {
      list-style: none;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: start;
      padding: 0;

      &>li {
        margin: 2px 0;
        width: 100%;

        &>a,
        &>div {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          padding: 8px 15px;
          gap: 15px;

          width: 244px;
          height: 41.45px;

          cursor: pointer;
          text-decoration: none;

          &>.title {
            display: flex;
            flex-direction: row;
            align-items: center;

            &>img {
              width: 20px;
              height: 20px;
              // filter: invert(100%);
            }

            &>span {
              margin-left: 18px;
              color: white;
              font-weight: 500;
              font-size: 16px;
              text-decoration: none;
            }
          }

          &>.MuiChip-root {
            background: transparent !important;
            color: $color-0 !important;
            font-size: 14px !important;

            &.MuiChip-colorSecondary {
              color: white !important;
            }
          }

          &.active {
            padding: 8px 15px;
            gap: 15px;
            width: 244px;
            height: 41.45px;
            background: #f3f6f7;
            border-radius: 10px;

            &>.title {
              &>span {
                color: #1b8392;
              }

              &>img {
                filter: invert(50%) sepia(85%) saturate(397%) hue-rotate(139deg) brightness(74%) contrast(100%);
              }
            }

            &>.MuiChip-root {
              color: #18699cc7 !important;

              &.MuiChip-colorSecondary {
                background: #e94a14 !important;
                color: white !important;
              }
            }
          }
        }
      }
    }
  }

  &>.content>.footer {
    position: relative;
    // bottom: 0;
    // left: 0;
    padding: 20px;
    width: 80%;
    text-align: center;
    border-top: 1.5px solid #99c6d3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 20px 15px 0px;
    gap: 30px;
    margin-bottom: 20%;

    &>span {
      color: white;
    }

    &>ul {
      list-style: none;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 15px;
      padding: 0;

      &>li {
        padding: 0;

        &>a>img {
          height: 24px;
          filter: invert(100%);
        }
      }
    }

    &>form>ul {
      list-style: none;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 28px;
      padding: 0;

      &>li {
        padding: 0;

        &>.title {
          display: flex !important;
          align-items: flex-start !important;
          justify-content: flex-start !important;

          &>label {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 27px;
            color: #f3f6f7 !important;
          }
        }
      }
    }

    &>.contacts {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 4px;

      &>.contact-phone {
        color: #2490f1;
        font-weight: bold;
        opacity: 1;
        background: white;
        padding: 2px 12px;
        border-radius: 20px;
        width: fit-content;
        margin-bottom: 12px;
        user-select: none;
      }
    }
  }

  &::after {
    content: '';
    position: absolute;
    bottom: -114px;
    left: -146px;
    width: 530px;
    height: 533px;
    transform: matrix(0.91, -0.42, 0.42, 0.91, 0, 0);
    box-shadow: 0 8px 30px rgba(30, 135, 240, 0.2);
    opacity: 0.5;
    border-radius: 50%;
    z-index: -9;
    background: transparent linear-gradient(180deg, #40bcf9, #1e87f0) 0 0 no-repeat padding-box;
  }
}

.css-12i7wg6-MuiPaper-root-MuiDrawer-paper {
  position: initial !important;
  height: 100vh !important;
}

.css-1d6w9lk-MuiButtonBase-root-MuiIconButton-root {
  padding: 0 !important;
}

.css-12i7wg6-MuiPaper-root-MuiDrawer-paper {
  font-family: 'Poppins' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 27px !important;
  display: flex !important;
  align-items: flex-start !important;
  color: #f3f6f7 !important;
}

.footer-establishment-title {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin: 0;
  width: 100%;

  &>h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #99c6d3;
  }
}

.title input[type='radio']:checked,
.title input[type='radio']:not(:checked) {
  position: absolute;
  left: -9999px;
}

.title input[type='radio']:checked+label,
.title input[type='radio']:not(:checked)+label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;

  display: inline-block;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #f3f6f7 !important;
}

.title input[type='radio']:checked+label:before,
.title input[type='radio']:not(:checked)+label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 16px;
  height: 16px;

  border-radius: 100%;
  background: #1b8392;
  border: 1px solid #f0f6f8;
}

.title input[type='radio']:checked+label:after,
.title input[type='radio']:not(:checked)+label:after {
  content: '';
  width: 8px;
  height: 8px;
  background: #fbb800;
  position: absolute;
  top: 4.5px;
  left: 4.5px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.title input[type='radio']:not(:checked)+label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.title input[type='radio']:checked+label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.ant-menu-light.ant-menu-root.ant-menu-vertical,
.ant-layout .ant-layout-sider-children {
  background-color: $color-2 !important;
  gap: 1px;

}

.ant-menu-submenu-selected .ant-menu-submenu-title,
.ant-menu-light .ant-menu-item-selected {
  background-color: $color-5 !important;
}

.ant-layout-content {
  margin: 0 !important;
  padding: 0.7rem 0rem 2rem 0rem !important;
  height: 100vh !important;
  min-height: 0px !important;
  overflow: scroll;
  background: $color-0 !important;
}

.ant-menu .ant-menu-item-selected .ant-menu-item-icon {
  filter: invert(50%) sepia(85%) saturate(397%) hue-rotate(139deg) brightness(74%) contrast(100%) !important;
}

.ant-menu-inline {
  padding: 0 0.5rem;
  gap: 5px !important;
}

.ant-menu-submenu-selected .ant-menu-submenu-title {
  svg {
    filter: invert(50%) sepia(85%) saturate(397%) hue-rotate(139deg) brightness(74%) contrast(100%) !important;
  }
}

.ant-menu-item-icon {
  min-width: 3rem !important;
  margin-left: -0.5rem !important;
}

.ant-menu .ant-menu-item .ant-menu-title-content {
  color: $color-5 !important;
}

.ant-menu .ant-menu-item-selected .ant-menu-title-content {
  color: $color-2 !important;
}

.ant-menu .ant-menu-submenu-selected .ant-menu-submenu-title .ant-menu-title-content {
  color: $color-2 !important;
}

.ant-menu .ant-menu-item-selected .ant-menu-submenu-title {
  filter: invert(50%) sepia(85%) saturate(397%) hue-rotate(139deg) brightness(74%) contrast(100%) !important;
}

.ant-menu-submenu-selected .ant-menu-submenu-arrow {
  filter: invert(50%) sepia(85%) saturate(397%) hue-rotate(139deg) brightness(74%) contrast(100%) !important;
}

.ant-menu .ant-menu-item {
  display: flex !important;
  align-items: center !important;
  justify-content: start !important;
  gap: 1rem;
  height: 42px !important;
  transition: all 0.3s ease-in-out !important;
}

.ant-layout-header {
  background-color: $color-4 !important;
}

.ant-layout-sider-children .logo {
  padding: 0px 0px 0px 10px !important;
  justify-content: space-between !important;
  border-color: transparent !important;
  align-items: center !important;
}

.ant-layout-sider {
  min-height: 100vh !important;
}

.header {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px 0 0;

  &-end {
    display: flex;
    align-items: center;
  }

  &-btn {
    display: flex;
    align-items: center;
  }

  &-notifs {
    margin-top: 0.6rem;
    background: none;
    border: none;
    color: transparent;
    cursor: pointer;
  }
}

.ant-layout-sider-children {
  display: flex;
  overflow-x: hidden;
  flex-direction: column;
  gap: 20px;
  overflow-y: scroll;
  height: 100vh !important;
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* IE 10+ */
  overflow: -moz-scrollbars-none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.ant-menu-title-content {
  font-size: 2rem;
}

.ant-layout.ant-layout-has-sider {
  overflow: hidden !important;
  height: 100vh;
}

.any-layout>svg {
  filter: brightness(0) invert(1);
}

.ant-menu-light.ant-menu-root.ant-menu-vertical {
  display: flex;
  flex-direction: column;
  gap: 15px;

  >* {
    line-height: 20px;
    font-weight: 400;
    font-size: 20px;
  }
}

.ant-menu-root {
  display: flex;
  flex-direction: column;
  padding: 3rem 0.5rem;
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner::after {
  background: #fbb800 !important;
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border-color: #f0f6f8;
  background: transparent;
}

.phone-sider-wrraper {
  transition: all 1s;
  position: absolute;
  z-index: 11;
  width: 100vw;
  height: 100vh;
  transition: all 0.2s !important;
  background-color: rgba(0, 0, 0, 0.5);
}

.ant-space-vertical {
  gap: 8px;

  padding-bottom: 8rem;
  overflow: scroll;
  height: 35vh;
  align-items: start !important;
}

.collapsed {
  align-items: center !important;
}

.sidebar-container {
  z-index: 999999999999999999;
  position: absolute;
  transition: transform 0.3s ease-in-out;

  .ant-layout-sider {
    border-bottom-right-radius: 50px;
    border-top-right-radius: 50px;
  }

  .ant-layout-sider-children {
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
  }
}

.ant-menu-submenu-title {
  padding-left: 25px !important;

  i,
  svg {
    filter: brightness(0) invert(1) !important;
  }
}

.ant-menu-title-content {
  color: white;
}

.teachers-sider {
  .ant-menu-sub {
    .ant-menu-item:first-child {
      .ant-menu-title-content {
        background-color: #f3f6f680 !important;
        padding: 0 10px !important;
        border-radius: 10px !important;
      }
    }
  }
}

.subjects-sider {
  .ant-menu-sub {
    .ant-menu-item:nth-child(2) {
      .ant-menu-title-content {
        background-color: #f3f6f680 !important;
        padding: 0 10px !important;
        border-radius: 10px !important;
      }
    }
  }
}

.establishements-sider {
  .ant-menu-sub {
    .ant-menu-item:last-child {
      .ant-menu-title-content {
        background-color: #f3f6f680 !important;

        border-radius: 10px !important;
      }
    }
  }
}

.ant-menu-submenu {
  .ant-menu-item-active {
    background: transparent !important;

    .ant-menu-title-content {
      background-color: rgba(0, 0, 0, 0.06) !important;

      border-radius: 10px;
    }
  }
}

.ant-menu-submenu {
  .ant-menu-title-content {
    padding: 0 10px;
  }
}

.ant-radio-group {
  overflow: hidden;
  margin: 0 0 0 8.5px;


}

.ant-layout-sider,
.ant-layout-sider-children {
  overflow: hidden;
}